import './Athletes.scss';

import React from 'react';

import BCAthleteVerticalItem from '../../../components/collabs/BCAthleteVerticalItem';
import { nftData } from '../../../drops/bcNILPageContent';
import { Button } from 'react-bootstrap';

const Athletes: React.FC<any> = () => {
	return (
		<div className="athletes" lang="en">
			<div className="bc-vertical-items">
				<BCAthleteVerticalItem
					itemContent={nftData['DeMarr Langford']}
					imageSrc={nftData['DeMarr Langford'].image}
					imageAlt={`Hero image for ${nftData['DeMarr Langford'].name}`}
				/>
				<BCAthleteVerticalItem
					itemContent={nftData['Cayla Barnes']}
					imageSrc={nftData['Cayla Barnes'].image}
					imageAlt={`Hero image for ${nftData['Cayla Barnes'].name}`}
				/>
				<BCAthleteVerticalItem
					itemContent={nftData['Zay Flowers']}
					imageSrc={nftData['Zay Flowers'].image}
					imageAlt={`Hero image for ${nftData['Zay Flowers'].name}`}
				/>
				<BCAthleteVerticalItem
					itemContent={nftData['Marne Sullivan']}
					imageSrc={nftData['Marne Sullivan'].image}
					imageAlt={`Hero image for ${nftData['Marne Sullivan'].name}`}
				/>
				<BCAthleteVerticalItem
					itemContent={nftData['Christian Mahogany']}
					imageSrc={nftData['Christian Mahogany'].image}
					imageAlt={`Hero image for ${nftData['Christian Mahogany'].name}`}
				/>
			</div>
			<div className="bc-banner"></div>
			<div className="bc-details">
				<h1>ENDSTATE U: New Heights</h1>
				<p>
					Endstate U: New Heights is a first-of-its-kind NIL
					collaboration with five elite athletes from Boston College:
					Zay Flowers, Cayla Barnes, DeMarr Langford, Christian
					Mahogany, and Marne Sullivan. Handpicked for their swagger,
					talent, kindness, and unique stories, each member of the New
					Heights team designed their own luxury, signature sneaker
					for the drop.
				</p>
				<p>
					The NFT and sneaker combination unlocks a special experience
					curated by each athlete in collaboration with the Endstate
					team. Each athlete is also releasing an autographed portrait
					NFT that entitles each customer to a physical signed photo
					of the athlete.
				</p>
				<p>
					The theme of the drop was inspired by “looking back to see
					how far we've come.” Close your eyes and imagine the year is
					1988. You’re going to school in Boston, the city where
					Endstate will be founded 40 years in the future. The Lakers
					just won another NBA title and George H. W. Bush was
					recently elected President.
				</p>
				<p>
					The future is bright but mysterious. There is no world wide
					web yet! Computers take five minutes to turn on. Many of you
					reading these words weren’t alive back then.
				</p>
				<p>
					“It’s fun to travel back in time because so much has
					developed in our culture since then. Now in 2023 you can buy
					a digital asset that unlocks a physical sneaker manufactured
					to the highest quality standards. That same sneaker has a
					chip inside of it that unlocks unique experiences. They
					might have been dreaming of flying cars back in 1988 but
					they surely couldn’t have anticipated Endstate sneakers. In
					fact, until last year, even the notion that a college
					athlete could have their own signature shoe deal was plainly
					against the rules. Playing professionally after they
					graduate should not be the only way for college athletes to
					achieve success,” said Stephanie Howard, Endstate cofounder.
				</p>
				<p>This is ENDSTATE U: New Heights</p>
			</div>
			<div className="bc-discord">
				<h2>Never Miss A drop</h2>
				<Button>JOIN DISCORD</Button>
			</div>
		</div>
	);
};

export default Athletes;
