import './ShippingForm.scss';

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import React from 'react';
import { CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import { Formik, Form as FormikForm } from 'formik';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';

export interface ShippingInfo {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	address1: string;
	address2: string;
	city: string;
	zip: string;
	country: string;
	province: string;
}

interface ShippingFormProps {
	loading: boolean;
	shippingInfo: ShippingInfo;
	onSubmit: (shippingInfo: ShippingInfo) => Promise<void>;
	error: string;
}
export const ShippingForm: React.FC<ShippingFormProps> = ({
	loading,
	shippingInfo,
	onSubmit,
	error,
}) => {
	console.log(error);
	countries.registerLocale(enLocale);
	const countryObj = countries.getNames('en', { select: 'official' });
	const countryArr = Object.entries(countryObj).map(([key, value]) => {
		return {
			label: value,
			value: key,
		};
	});

	const getProvinceSelector = (
		country: string,
		value: string,
		handleChange: any,
		errorProvince: string | undefined,
		touchedProvince: boolean | undefined
	) => {
		if (country === 'US') {
			let countryData = CountryRegionData.filter(
				(country) => country[1] === 'US'
			)[0];
			let usStates = ['SELECT ONE', ...countryData[2].split('|')];
			return (
				<Form.Select
					name="province"
					value={value}
					onChange={handleChange}
				>
					{usStates?.map((usState) => {
						let stateName = usState.split('~')[0];
						return (
							<option key={usState} value={stateName}>
								{stateName}
							</option>
						);
					})}
					{touchedProvince && errorProvince && (
						<p className="form-error">{errorProvince}</p>
					)}
				</Form.Select>
			);
		} else {
			return (
				<>
					<Form.Control
						type="text"
						placeholder="PROVINCE"
						onChange={handleChange}
						value={value}
					/>
					{touchedProvince && errorProvince && (
						<p className="form-error">{errorProvince}</p>
					)}
				</>
			);
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					firstName: shippingInfo.firstName,
					lastName: shippingInfo.lastName,
					address1: shippingInfo.address1,
					address2: shippingInfo.address2,
					city: shippingInfo.city,
					zip: shippingInfo.zip,
					country: shippingInfo.country,
					province: shippingInfo.province,
					email: shippingInfo.email,
					phone: shippingInfo.phone,
				}}
				enableReinitialize={false}
				validationSchema={Yup.object().shape({
					firstName: Yup.string()
						.min(1, 'First name is too short')
						.required('First name required'),
					lastName: Yup.string()
						.min(1, 'Last name is too short')
						.required('Last name required'),
					address1: Yup.string().required('Address required'),
					zip: Yup.string().required('Zip code required'),
					city: Yup.string().required('City required'),
					province: Yup.string().required('Province required'),
					email: Yup.string()
						.email('Invalid email')
						.required('Email required'),
					phone: Yup.string()
						.matches(
							/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
							'Invalid phone number'
						)
						.required('Phone number required'),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					await onSubmit(values);
					setSubmitting(false);
				}}
			>
				{({
					values,
					submitForm,
					errors,
					touched,
					handleChange,
					isSubmitting,
				}) => (
					<FormikForm className="shipping-form">
						<div>
							<Form.Group
								className="form-input"
								controlId="firstName"
							>
								<Form.Control
									type="text"
									placeholder="FIRST NAME"
									onChange={handleChange}
									value={values.firstName}
								/>
								{touched.firstName && errors.firstName && (
									<p className="form-error">
										{errors.firstName}
									</p>
								)}
							</Form.Group>
							<Form.Group
								className="form-input"
								controlId="lastName"
							>
								<Form.Control
									type="text"
									placeholder="LAST NAME"
									onChange={handleChange}
									value={values.lastName}
								/>
								{touched.lastName && errors.lastName && (
									<p className="form-error">
										{errors.lastName}
									</p>
								)}
							</Form.Group>
						</div>
						<div>
							<Form.Group
								className="form-input"
								controlId="address1"
							>
								<Form.Control
									type="text"
									placeholder="ADDRESS"
									onChange={handleChange}
									value={values.address1}
								/>
								{touched.address1 && errors.address1 && (
									<p className="form-error">
										{errors.address1}
									</p>
								)}
							</Form.Group>
						</div>
						<div>
							<Form.Group
								className="form-input"
								controlId="address2"
							>
								<Form.Control
									type="text"
									placeholder="APT, SUITE (OPTIONAL)"
									onChange={handleChange}
									value={values.address2}
								/>
							</Form.Group>
						</div>
						<div>
							<Form.Group className="form-input" controlId="city">
								<Form.Control
									type="text"
									placeholder="CITY"
									onChange={handleChange}
									value={values.city}
								/>
								{touched.city && errors.city && (
									<p className="form-error">{errors.city}</p>
								)}
							</Form.Group>
							<Form.Group className="form-input" controlId="zip">
								<Form.Control
									type="text"
									placeholder="ZIP CODE"
									onChange={handleChange}
									value={values.zip}
								/>
								{touched.zip && errors.zip && (
									<p className="form-error">{errors.zip}</p>
								)}
							</Form.Group>
						</div>
						<div>
							<Form.Group
								className="form-input"
								controlId="country"
							>
								<Form.Select
									name="country"
									value={values.country}
									onChange={handleChange}
								>
									{!!countryArr?.length &&
										countryArr.map(({ label, value }) => (
											<option key={value} value={value}>
												{label}
											</option>
										))}
								</Form.Select>
							</Form.Group>
							<Form.Group
								className="form-input"
								controlId="province"
							>
								{getProvinceSelector(
									values.country,
									values.province,
									handleChange,
									errors.province,
									touched.province
								)}
								{touched.province && errors.province && (
									<p className="form-error">
										{errors.province}
									</p>
								)}
							</Form.Group>
						</div>
						<div>
							<Form.Group
								className="form-input"
								controlId="email"
							>
								<Form.Control
									type="email"
									placeholder="EMAIL"
									onChange={handleChange}
									value={values.email}
								/>
								{touched.email && errors.email && (
									<p className="form-error">{errors.email}</p>
								)}
							</Form.Group>
						</div>
						<div>
							<Form.Group
								className="form-input"
								controlId="email"
							>
								<PhoneInput
									country={'us'}
									specialLabel=""
									disableCountryCode={true}
									placeholder="PHONE NUMBER"
									disableCountryGuess
									value={values.phone}
									onChange={(e: any) => {
										handleChange({
											target: {
												name: 'phone',
												value: e,
											},
										});
									}}
								/>
								{touched.phone && errors.phone && (
									<p className="form-error">{errors.phone}</p>
								)}
							</Form.Group>
						</div>
						{error?.length > 0 && (
							<p className="form-error">{error}</p>
						)}
						<div className="devonta-mint-modal-actions">
							<Button
								disabled={isSubmitting || loading}
								onClick={(e) => {
									submitForm();
								}}
							>
								{loading ? 'LOADING...' : 'CHECKOUT'}
							</Button>
						</div>
					</FormikForm>
				)}
			</Formik>
		</>
	);
};
