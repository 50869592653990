import { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { blitkickContractAddress } from '../blockchain/contracts';
import BlitkickJSON from '../blockchain/PubliclyMintable.json';
import { web3 } from '../blockchain/web3Utils';

const { persistAtom } = recoilPersist();

const Contract = require('web3-eth-contract');

const autoConnectWalletState = atom({
	key: 'autoConnectWalletState',
	default: false,
	effects_UNSTABLE: [persistAtom],
});

const types: any = {
	blitkick: {
		json: BlitkickJSON,
		contractAddress: blitkickContractAddress,
	},
};

const useEthereumWeb3 = () => {
	// @ts-ignore
	const ethereum = window.ethereum;

	// @ts-ignore
	const isMetaMaskAvailable = typeof ethereum !== 'undefined';

	const [connectedWallet, setConnectedWallet] = useState<string | undefined>(
		undefined
	);
	const [autoConnectWallet, setAutoConnectWallet] = useRecoilState(
		autoConnectWalletState
	);

	const requestConnectWallet = useCallback(async () => {
		if (ethereum) {
			const accounts = await ethereum.request({
				method: 'eth_requestAccounts',
			});
			if (accounts.length && accounts[0]) {
				const accountAddress: string = accounts[0];
				setConnectedWallet(accountAddress);
				setAutoConnectWallet(true);
				return accountAddress;
			}
		}
	}, [ethereum, setAutoConnectWallet]);

	const disconnectWallet = () => {
		setAutoConnectWallet(false);
		setConnectedWallet(undefined);
	};

	useEffect(() => {
		if (!connectedWallet && autoConnectWallet) {
			requestConnectWallet();
		}
	}, [autoConnectWallet, connectedWallet, requestConnectWallet]);

	const initContract: any = async (type: string) => {
		const contractData = types[type.toLowerCase()];
		Contract.setProvider(web3.currentProvider);

		const contract = new Contract(
			contractData.json.abi,
			contractData.contractAddress
		);

		return { contract, contractData };
	};

	return {
		isMetaMaskAvailable,
		initContract,
		ethereum,
		web3,
		requestConnectWallet,
		disconnectWallet,
		connectedWallet,
	};
};

export default useEthereumWeb3;
