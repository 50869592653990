import "./Drop2NFTModal.scss";

import { Button, Modal } from "react-bootstrap";
import { drop2ContractAddress } from "../../blockchain/contracts";
import { FaRegTimesCircle } from "react-icons/fa";

const Drop2NFTModal: React.FC<any> = (props) => {
  const { nftAnimationUri, show, onHide, nftId, attributes } = props;

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="">
      <div className="drop2-nft">
        <div className="drop2-nft-close" onClick={onHide}>
          <FaRegTimesCircle size={32} />
        </div>
        <video
          className="drop2-nft-image"
          src={nftAnimationUri}
          loop
          autoPlay
          muted
        />
        <h1>Drop 2: Statecraft - #{nftId}</h1>
        <div className="drop2-nft-attributes">
          <p>
            <b>Embellishment</b>
            <br />
            {attributes["embellishment"]}
          </p>
          <p>
            <b>Pillar</b>
            <br />
            {attributes["pillar"]}
          </p>
          <p>
            <b>Environment Design</b>
            <br />
            {attributes["environment"]}
          </p>
        </div>
        <Button
          className="drop2-mint-button"
          onClick={() => {
            const url = `https://opensea.io/assets/ethereum/${drop2ContractAddress}/${nftId}`;
            window.open(url, "_blank", "noopener,noreferrer");
          }}
        >
          VIEW ON OPENSEA
        </Button>
      </div>
    </Modal>
  );
};

export default Drop2NFTModal;
