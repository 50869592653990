import marneCoverSneakerImage from '../assets/images/newheights/MarneSneaker.jpg';
import marnePortraitNFTImage from '../assets/images/newheights/MarneSneaker.jpg';
import marneHeroSneakerImage from '../assets/images/newheights/MarneSneaker_Wide.jpg';

import mariaCoverSneakerImage from '../assets/images/newheights/MariaSneaker.jpg';
import mariaPortraitNFTImage from '../assets/images/newheights/MariaSneaker.jpg';
import mariaHeroSneakerImage from '../assets/images/newheights/MariaSneaker_Wide.jpg';

import caylaCoverSneakerImage from '../assets/images/newheights/CaylaSneaker.jpg';
import caylaPortraitNFTImage from '../assets/images/newheights/CaylaSneaker.jpg';
import caylaHeroSneakerImage from '../assets/images/newheights/CaylaSneaker_Wide.jpg';

import zayCoverSneakerImage from '../assets/images/newheights/ZaySneaker.jpg';
import zayPortraitNFTImage from '../assets/images/newheights/ZaySneaker.jpg';
import zayHeroSneakerImage from '../assets/images/newheights/ZaySneaker_Wide.jpg';

import christianCoverSneakerImage from '../assets/images/newheights/ChristianSneaker.jpg';
import christianPortraitNFTImage from '../assets/images/newheights/ChristianSneaker.jpg';
import christianHeroSneakerImage from '../assets/images/newheights/ChristianSneaker_Wide.jpg';

import demarrCoverSneakerImage from '../assets/images/newheights/DemarrSneaker.jpg';
import demarrPortraitNFTImage from '../assets/images/newheights/DemarrSneaker.jpg';
import demarrHeroSneakerImage from '../assets/images/newheights/DemarrSneaker_Wide.jpg';

import IDropData from '../models/IDropData';

export const BCNILNFTData = {
	MARNE_SULLIVAN_NFT: {
		dropName: 'BC NIL',
		// variantId: 44370594758938, // Staging
		// productId: 8082594922778, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738086211804
				: 44370594758938, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961667895516
				: 8082594922778, // Prod
		image: marnePortraitNFTImage,
		name: 'Marne Sullivan NFT',
		description: '',
		link: '',
	},
	MARIA_GAKDENG_NFT: {
		dropName: 'BC NIL',
		// variantId: 44370594890010, // Staging
		// productId: 8082595053850, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738097647836
				: 44370594890010, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961671794908
				: 8082595053850, // Prod
		image: mariaPortraitNFTImage,
		name: 'Maria Gakdeng NFT',
		description: '',
		link: '',
	},
	CAYLA_BARNES_NFT: {
		dropName: 'BC NIL',
		// variantId: 44370594791706, // Staging
		// productId: 8082594955546, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738090930396
				: 44370594791706, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961669402844
				: 8082594955546, // Prod
		image: caylaPortraitNFTImage,
		name: 'Cayla Barnes NFT',
		description: '',
		link: '',
	},
	ZAY_FLOWERS_NFT: {
		dropName: 'BC NIL',
		// variantId: 44370594922778, // Staging
		// productId: 8082595086618, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738101448924
				: 44651805180186, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961672417500
				: 8082595086618, // Prod
		image: zayPortraitNFTImage,
		name: 'Zay Flowers NFT',
		description: '',
		link: '',
	},
	CHRISTIAN_MAHOGANY_NFT: {
		dropName: 'BC NIL',
		// variantId: 44370594824474, // Staging
		// productId: 8082594988314, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738093420764
				: 44370594824474, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961670549724
				: 8082594988314, // Prod
		image: christianPortraitNFTImage,
		name: 'Christian Mahogany NFT',
		description: '',
		link: '',
	},
	DEMARR_LANGFORD_NFT: {
		dropName: 'BC NIL',
		// variantId: 44370594857242, // Staging
		// productId: 8082595021082, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738096369884
				: 44370594857242, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961671467228
				: 8082595021082, // Prod
		image: demarrPortraitNFTImage,
		name: 'Demarr Langford NFT',
		description: '',
		link: '',
	},
};

export const nftData: { [key: string]: IDropData } = {
	'Marne Sullivan': {
		dropName: 'BC NIL',
		// variantId: 44361991946522, // Staging
		// productId: 8080517431578, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43730965758172
				: 44361991946522, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7958995468508
				: 8080517431578, // Prod
		image: marneCoverSneakerImage,
		heroImage: marneHeroSneakerImage,
		name: 'Marne Sullivan',
		description:
			"Endstate collaborated with Boston College track and field star Marne Sullivan to release her first ever signature sneaker and NFT. Marne designed her sneaker to proudly reference her experience competing at the highest level as a deaf athlete with a cochlear implant. A portion of the proceeds from Marne's sneaker will be donated to the Joyful Life Cochlear Foundation.",
		link: '/EndstateU/marne-sullivan',
		extras: {
			nftOnly: BCNILNFTData.MARNE_SULLIVAN_NFT,
			sport: 'Track',
			quote: '“I collaborated with Endstate because there is no one innovating in the footwear space like them.”',
			sneakerDescription:
				'Marne’s sneaker, built upon the Statecraft silhouette, features the silhouette of a cochlear implant on the toe box, her favorite color pink on the ankle, and her favorite quote on the heel: “Can’t Hear You When You’re Behind Me.”',
			portraitDescription:
				'Receive a premium, signed portrait of Marne when you purchase her portrait NFT.',
			charityName: 'The Joyful Life Cochlear Foundation',
			charityDescription:
				'The Joyful Life Cochlear strives to optimize the hearing experience and lives of cochlear implant recipients by providing confidence, coaching, and product support.',
		},
	},
	'Maria Gakdeng': {
		dropName: 'BC NIL',
		// variantId: 44361991979290, // Staging
		// productId: 8080517464346, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43730967199964
				: 44361991979290, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7958997106908
				: 8080517464346, // Prod
		image: mariaCoverSneakerImage,
		heroImage: mariaHeroSneakerImage,
		name: 'Maria Gakdeng',
		description:
			"Endstate collaborated with Boston College basketball star Maria Gakdeng to release her first ever signature sneaker and NFT. Maria’s sneaker features the South Sudanese flag and her favorite Bible quote. A portion of the proceeds from Maria's sneaker will be donated to the Task Force for Global Health.",

		link: '/EndstateU/maria-gakdeng',
		extras: {
			nftOnly: BCNILNFTData.MARIA_GAKDENG_NFT,
			sport: 'Basketball',
			quote: '“Endstate makes me feel like Mike. It’s that simple.”',
			sneakerDescription:
				'Maria designed her sneaker to prominently feature the flag of South Sudan, her family’s country of origin, and her signature against a black custom label. Maria\'s sneaker also features the phrase "My God in whom I trust," a quote from the Bible that she lives by.',
			portraitDescription:
				'Receive a premium, signed portrait of Maria when you purchase her portrait NFT.',
			charityName: 'Task Force for Global Health',
			charityDescription:
				'The Task Force for Global Health works with partners around the globe to eliminate diseases that have plagued humanity for centuries and protect the health of populations by helping countries build strong health systems.',
		},
	},
	'Cayla Barnes': {
		dropName: 'BC NIL',
		// variantId: 44370595873050, // Staging
		// productId: 8082595381530, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738060325084
				: 44370595873050, // prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961657147612
				: 8082595381530, // Prod
		image: caylaCoverSneakerImage,
		heroImage: caylaHeroSneakerImage,
		name: 'Cayla Barnes',
		description:
			"Endstate collaborated with Boston College hockey star Cayla Barnes to release her first ever signature sneaker and NFT. Cayla's patriotic sneaker references her family’s military service and her time as an Olympian. A portion of the proceeds from Cayla's sneaker will be donated to support the Wounded Warrior Project.",

		link: '/EndstateU/cayla-barnes',
		extras: {
			nftOnly: BCNILNFTData.CAYLA_BARNES_NFT,
			sport: 'Hockey',
			quote: '“Endstate flexes who I am and what’s important to me through my shoe.”',
			sneakerDescription:
				"Cayla's sneaker, built upon Endstate’s Statecraft silhouette, features an American flag on the toe cap as a reference to her time on the USA Women’s Hockey team, “USMC” on the heel, to honor her family members who have served in the US military, and her signature against a royal blue label on the ankle.",
			portraitDescription:
				'Receive a premium, signed portrait of Cayla when you purchase her portrait NFT.',
			charityName: 'The Wounded Warrior Project',
			charityDescription:
				'The Wounded Warrior Project is an American charity and veterans service organization that offers a variety of programs, services and events for wounded veterans of the military actions following September 11, 2001.',
		},
	},
	'Zay Flowers': {
		dropName: 'BC NIL',
		// variantId: 44370596069658, // Staging
		// productId: 8082595479834, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738067108060
				: 44370596069658, // prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961660686556
				: 8082595479834, // Prod
		image: zayCoverSneakerImage,
		heroImage: zayHeroSneakerImage,
		name: 'Zay Flowers',
		description:
			"Endstate collaborated with Boston College football star Zay Flowers to release his first ever signature sneaker. Zay’s sneaker design references his dominant career on the field where he proudly wore his favorite number four. A portion of the proceeds from Zay's sneaker will be donated to support the American Red Cross.",

		link: '/EndstateU/zay-flowers',
		extras: {
			nftOnly: BCNILNFTData.ZAY_FLOWERS_NFT,
			sport: 'Football',
			quote: '“Endstate is a great company, I’ll never forget my first sneaker with them"',
			sneakerDescription:
				'Zay designed his sneaker to prominently feature his own signature logo, a reference to his last name ‘Flowers’ composed of his own initials. The sneakers also boast his signature on the toe cap along with a custom label featuring the number 4, his favorite number that he donned throughout his record breaking collegiate career.',
			portraitDescription:
				'Receive a premium, signed portrait of Zay when you purchase his portrait NFT.',
			charityName: 'The American Red Cross',
			charityDescription:
				'The American Red Cross is a non-profit humanitarian organization that provides emergency assistance, disaster relief, and disaster preparedness education in the United States.',
		},
	},
	'Christian Mahogany': {
		dropName: 'BC NIL',
		// variantId: 44370595905818, // Staging
		// productId: 8082595414298, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738064453852
				: 44370595905818, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961659441372
				: 8082595414298, // Prod
		image: christianCoverSneakerImage,
		heroImage: christianHeroSneakerImage,
		name: 'Christian Mahogany',
		description:
			"Endstate collaborated with Boston College football star Christian Mahogany to release his first ever signature sneaker and NFT. Christian designed his sneaker to prominently feature a family motto, his initials, and his number on the football field. A portion of the proceeds from Christian's sneaker will be donated to The Susan G. Komen Foundation.",

		link: '/EndstateU/christian-mahogany',
		extras: {
			nftOnly: BCNILNFTData.CHRISTIAN_MAHOGANY_NFT,
			sport: 'Football',
			quote: '“Endstate took a bet on me, and it’s going to pay off.”',
			sneakerDescription:
				'Christian designed his sneaker to prominently feature his favorite motto, passed down to him by his grandmother and father, "Tough Times Don\'t Last, Tough People Do." Christian\'s sneaker also features his initials, his football number 73, and a ribbon representing his dedication to supporting cancer research.',
			portraitDescription:
				'Receive a premium, signed portrait of Christian when you purchase his portrait NFT.',
			charityName: 'The Susan G. Komen Foundation',
			charityDescription:
				'The Susan G. Komen Foundation focuses on patient navigation and advocacy, providing resources for breast cancer patients to understand the American medical system. It also funds research into the causes and treatment of breast cancer.',
		},
	},
	'DeMarr Langford': {
		dropName: 'BC NIL',
		// variantId: 44370596102426, // Staging
		// productId: 8082595512602, // Staging
		variantId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 43738067861724
				: 44370596102426, // Prod
		productId:
			process.env.REACT_APP_NODE_ENV === 'production'
				? 7961661210844
				: 8082595512602, // Prod
		image: demarrCoverSneakerImage,
		heroImage: demarrHeroSneakerImage,
		name: 'DeMarr Langford',
		description:
			"Endstate collaborated with Boston College basketball star DeMarr Langford to release his first ever signature sneaker and NFT. DeMarr's sneaker features several references to his family and is highlighted by his mother’s favorite color: Reservoir Green. A portion of the proceeds from DeMarr's sneaker will be donated to the Yes We Care foundation.",

		link: '/EndstateU/demarr-langford',
		extras: {
			nftOnly: BCNILNFTData.DEMARR_LANGFORD_NFT,
			sport: 'Basketball',
			quote: '“I love Endstate and wanted to start somewhere that would champion my name and empower me to build my brand.”',
			sneakerDescription:
				'DeMarr\'s sneaker, built upon Endstate’s Statecraft silhouette, features "Boo From The Woo," on the heel panel, a phrase with family ties and references his hometown of Worcester, Massachusetts. The Reservoir Green toe panel, custom labels, and laces are a tribute to his mother.',
			portraitDescription:
				'Receive a premium, signed portrait of DeMarr when you purchase his portrait NFT.',
			charityName: 'The Yes We Care Foundation',
			charityDescription:
				'The Yes We Care Foundation works to provide multi-generational, comprehensive programs for under-served, overlooked, low to moderate-income families, youth, and Black, Indigenous and people of color.',
		},
	},
};

export const BCNILSizes = [
	'US M 3.5 | W 5',
	'US M 4 | W 5.5',
	'US M 4.5 | W 6',
	'US M 5 | W 6.5',
	'US M 5.5 | W 7',
	'US M 6 | W 7.5',
	'US M 6.5 | W 8',
	'US M 7 | W 8.5',
	'US M 7.5 | W 9',
	'US M 8 | W 9.5',
	'US M 8.5 | W 10',
	'US M 9 | W 10.5',
	'US M 9.5 | W 11',
	'US M 10 | W 11.5',
	'US M 10.5 | W 12',
	'US M 11 | W 12.5',
	'US M 11.5 | W 13',
	'US M 12 | W 13.5',
	'US M 12.5 | W 14',
	'US M 13 | W 14.5',
	'US M 13.5 | W 15',
	'US M 14 | W 15.5',
	'US M 15 | W 16.5',
	'US M 16 | W 17.5',
];

const genericSneakerAttributes = [
	{ key: 'Release', value: 'Endstate U: New Heights' },
	{ key: 'Silhouette', value: 'Statecraft' },
	{ key: 'Colorway', value: undefined },
	{ key: 'Designer Director', value: 'Stephanie Howard' },
	{ key: 'Lead Designer', value: 'David Filar' },
	{ key: 'Collaborator', value: undefined },
	{ key: 'Primary Color', value: undefined },
	{ key: 'Secondary Color', value: undefined },
	{ key: 'Tertiary Color', value: undefined },
	{ key: 'Made In', value: 'Portugal' },
	{ key: 'Lace Color', value: undefined },
	{
		key: 'Environment',
		value: 'Sub Turri',
	},
	{ key: 'Motif', value: undefined },
	{ key: 'Lace Location', value: 'Tranverse Tongue' },
	{ key: 'Design Pattern', value: 'Classic Paneling' },
	{ key: 'Design Style', value: 'Cut-and-sew' },
	{ key: 'Midsole Color', value: undefined },
	{ key: 'Outsole Color', value: undefined },
	{ key: 'Heel Pattern', value: undefined },
	{ key: 'Logo Color', value: 'Ink Black' },
	{ key: 'Medial Logo Color', value: 'Ink Black' },
	{ key: 'Upper', value: 'Premium Leather' },
	{ key: 'Sole', value: 'Polyurethane' },
];

const sneakerAttributesByColorway: { [key: string]: any[] } = {
	'Marne Sullivan': [
		{ key: 'Collaborator', value: 'Marne Sullivan' },
		{ key: 'Colorway', value: 'Sullivan' },
		{ key: 'Primary Color', value: 'Ivory Heights' },
		{ key: 'Secondary Color', value: 'Commonwealth Maroon' },
		{ key: 'Tertiary Color', value: 'Hot Pink' },
		{ key: 'Lace Color', value: 'Ivory Heights' },
		{ key: 'Midsole Color', value: 'Ivory Heights' },
		{ key: 'Outsole Color', value: 'Ink Black' },
		{ key: 'Motif', value: 'MarneSully' },
		{ key: 'Heel Pattern', value: "Can't Hear You When You're Behind Me" },
	],
	'Zay Flowers': [
		{ key: 'Collaborator', value: 'Zay Flowers' },
		{ key: 'Colorway', value: 'Flowers' },
		{ key: 'Primary Color', value: 'Ivory Heights' },
		{ key: 'Secondary Color', value: 'Commonwealth Maroon' },
		{ key: 'Tertiary Color', value: 'Ink Black' },
		{ key: 'Lace Color', value: 'Ink Black' },
		{ key: 'Midsole Color', value: 'Ivory Heights' },
		{ key: 'Outsole Color', value: 'Ink Black' },
		{ key: 'Motif', value: 'Four' },
		{ key: 'Heel Pattern', value: 'Flowers' },
	],
	'Christian Mahogany': [
		{ key: 'Collaborator', value: 'Christian Mahogany' },
		{ key: 'Colorway', value: 'Mahogany' },
		{ key: 'Primary Color', value: 'Ivory Heights' },
		{ key: 'Secondary Color', value: 'Commonwealth Maroon' },
		{ key: 'Tertiary Color', value: 'Ink Black' },
		{ key: 'Lace Color', value: 'Ivory Heights' },
		{ key: 'Midsole Color', value: 'Ivory Heights' },
		{ key: 'Outsole Color', value: 'Ink Black' },
		{ key: 'Motif', value: "Tough Times Don't Last. Tough People Do." },
		{ key: 'Heel Pattern', value: 'Ribbon' },
	],
	'Maria Gakdeng': [
		{ key: 'Collaborator', value: 'Maria Gakdeng' },
		{ key: 'Colorway', value: 'Gakdeng' },
		{ key: 'Primary Color', value: 'Ivory Heights' },
		{ key: 'Secondary Color', value: 'Commonwealth Maroon' },
		{ key: 'Tertiary Color', value: 'Ink Black' },
		{ key: 'Lace Color', value: 'Ivory Heights' },
		{ key: 'Midsole Color', value: 'Ivory Heights' },
		{ key: 'Outsole Color', value: 'Ink Black' },
		{ key: 'Motif', value: "Maria's Signature" },
		{ key: 'Heel Pattern', value: 'South Sudan Flag' },
	],
	'DeMarr Langford': [
		{ key: 'Collaborator', value: 'DeMarr Langford' },
		{ key: 'Colorway', value: 'Langford' },
		{ key: 'Primary Color', value: 'Ivory Heights' },
		{ key: 'Secondary Color', value: 'Commonwealth Maroon' },
		{ key: 'Tertiary Color', value: 'Reservoir Green' },
		{ key: 'Lace Color', value: 'Reservoir Green' },
		{ key: 'Midsole Color', value: 'Ivory Heights' },
		{ key: 'Outsole Color', value: 'Ink Black' },
		{ key: 'Motif', value: 'Boo From The Woo' },
		{ key: 'Heel Pattern', value: '508' },
	],
	'Cayla Barnes': [
		{ key: 'Collaborator', value: 'Cayla Barnes' },
		{ key: 'Colorway', value: 'Barnes' },
		{ key: 'Primary Color', value: 'Ivory Heights' },
		{ key: 'Secondary Color', value: 'Commonwealth Maroon' },
		{ key: 'Tertiary Color', value: 'Ivory Heights' },
		{ key: 'Lace Color', value: 'Ivory Heights' },
		{ key: 'Midsole Color', value: 'Ivory Heights' },
		{ key: 'Outsole Color', value: 'Ink Black' },
		{ key: 'Motif', value: 'American Flag' },
		{ key: 'Heel Pattern', value: 'USMC' },
	],
};

const sneakerPerks: { [key: string]: string[] } = {
	physical: [
		"• One pair of {athlete}'s sneakers",
		'• Luxury Italian leather upper',
		'• Military-grade polyurethane midsole and solid rubber outsole',
		'• Made in Portugal. Soles made in the USA',
	],
	digital: [
		'• 3D rendering of Endstate U: New Heights sneakers',
		'• Entry into The State Department channel within the Endstate Discord',
		'• Future metaverse integrations',
	],
	experiential: [
		'• One personalized video from {athlete}',
		'• Meet {athlete}',
		'• Exclusive access to in-person Endstate experience',
		'• Each pair of sneakers is authenticated and verifiably linked to its one-of-one NFT',
	],
};

const sneakerPerksZay: { [key: string]: string[] } = {
	physical: [
		"• One pair of {athlete}'s sneakers",
		'• Luxury Italian leather upper',
		'• Military-grade polyurethane midsole and solid rubber outsole',
		'• Made in Portugal. Soles made in the USA',
	],
	digital: [
		'• 3D rendering of Endstate U: New Heights sneakers',
		'• Entry into The State Department channel within the Endstate Discord',
		'• Future metaverse integrations',
	],
	experiential: [
		'• Meet Zay at an event after his Pro Day in Boston',
		'• Authentication via NFC chip embedded in sneaker',
	],
};

const genericPortraitAttributes = [
	{ key: 'Release', value: 'Endstate U: New Heights' },
	{ key: 'Athlete', value: undefined },
	{ key: 'Sport', value: undefined },
	{ key: 'Class', value: undefined },
	{ key: 'Hometown', value: undefined },
	{ key: 'Highschool', value: undefined },
	{ key: 'Environment', value: 'Sub Turri Lazers' },
];

const portraitAttributesByColorway: { [key: string]: any[] } = {
	'Marne Sullivan': [
		{ key: 'Athlete', value: 'Marne Sullivan' },
		{ key: 'Sport', value: 'Track & Field' },
		{ key: 'Class', value: 'Redshirt Junior' },
		{ key: 'Hometown', value: 'Winnetka, Illinois' },
		{ key: 'Highschool', value: 'New Trier' },
	],
	'Zay Flowers': [
		{ key: 'Athlete', value: 'Zay Flowers' },
		{ key: 'Sport', value: 'Football' },
		{ key: 'Class', value: 'Senior' },
		{ key: 'Hometown', value: 'Fort Lauderdale, Florida' },
		{ key: 'Highschool', value: 'Fort Lauderdale, Florida' },
	],
	'Christian Mahogany': [
		{ key: 'Athlete', value: 'Christian Mahogany' },
		{ key: 'Sport', value: 'Football' },
		{ key: 'Class', value: 'Redshirt Junior' },
		{ key: 'Hometown', value: 'Elmwood Park, New Jersey' },
		{ key: 'Highschool', value: 'Paramus Catholic' },
	],
	'Maria Gakdeng': [
		{ key: 'Athlete', value: 'Maria Gakdeng' },
		{ key: 'Sport', value: 'Basketball' },
		{ key: 'Class', value: 'Sophomore' },
		{ key: 'Hometown', value: 'Lanham, Maryland' },
		{ key: 'Highschool', value: 'New Hope Academy' },
	],
	'DeMarr Langford': [
		{ key: 'Athlete', value: 'DeMarr Langford' },
		{ key: 'Sport', value: 'Basketball' },
		{ key: 'Class', value: 'Junior' },
		{ key: 'Hometown', value: 'Worcester, Masschusetts' },
		{ key: 'Highschool', value: 'Brewster Academy' },
	],
	'Cayla Barnes': [
		{ key: 'Athlete', value: 'Cayla Barnes' },
		{ key: 'Sport', value: 'Hockey' },
		{ key: 'Class', value: 'Senior' },
		{ key: 'Hometown', value: 'Eastvale, California' },
		{ key: 'Highschool', value: 'New Hampton School' },
	],
};

const portraitPerks: { [key: string]: string[] } = {
	physical: ["• One copy of {athlete}'s autographed portrait"],
	digital: ['• NFT of {athlete}’s autographed portrait'],
	experiential: [
		'• Each portrait is authenticated and verifiably linked to its one-of-one NFT',
		'• Exclusive access to in-person Endstate experience',
	],
};

const getBCNILPageContent = (colorway: string) => {
	const sneakerAttributes: any[] = structuredClone(genericSneakerAttributes);

	sneakerAttributes.forEach((attr) => {
		if (attr.value === undefined) {
			attr.value = sneakerAttributesByColorway[colorway].find(
				(abc) => abc.key === attr.key
			)?.value;
		}
	});

	const portraitAttributes: any[] = structuredClone(
		genericPortraitAttributes
	);

	portraitAttributes.forEach((attr) => {
		if (attr.value === undefined) {
			attr.value = portraitAttributesByColorway[colorway].find(
				(abc) => abc.key === attr.key
			)?.value;
		}
	});

	const perks = colorway === 'Zay Flowers' ? sneakerPerksZay : sneakerPerks;

	return {
		sneakerAttributes,
		portraitAttributes,
		sneakerPerks: perks,
		portraitPerks,
		nftData: nftData[colorway],
	};
};

export default getBCNILPageContent;
