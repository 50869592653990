import './WalletModal.scss';

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaRegTimesCircle } from 'react-icons/fa';
import { useAccount } from 'wagmi';
import useShopify from '../../hooks/useShopify';

const WalletConfirmModal: React.FC<{ show: boolean; onHide: () => any }> = (
	props
) => {
	const { show, onHide } = props;

	const { address } = useAccount();
	const { proceedToPurchase, getCheckout } = useShopify();

	return (
		<Modal show={show} onHide={onHide} centered dialogClassName="">
			<div className="d-flex flex-column p-4">
				<div className="modal-close" onClick={onHide}>
					<FaRegTimesCircle size={32} />
				</div>
				<p>
					Once you've successully completed checkout, the NFTs will be
					transferred into the following wallet:
				</p>
				<p>
					<b>{address}</b>
				</p>
				<p>Is this the correct address? This cannot be undone.</p>
				<div className="d-flex flex-row justify-content-center">
					<div className="mx-4">
						<Button variant="dark">No it is not</Button>
					</div>
					<div className="mx-4">
						<Button
							onClick={async () =>
								proceedToPurchase(await getCheckout())
							}
						>
							Yes it is
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default WalletConfirmModal;
