import './Cart.scss';

import React, { useState, useEffect, useCallback } from 'react';
import useShopify from '../../hooks/useShopify';

import { Button, Form } from 'react-bootstrap';
import WalletConfirmModal from '../../components/cart/WalletConfirmModal';
import { useAccount } from 'wagmi';
import NoWalletModal from '../../components/cart/NoWalletModal';
import { LinkConnectButton } from '../../components/cart/LinkConnectButton';
import { isMobile } from 'react-device-detect';
import CartLineItem from './CartLineItem';
import ReactGA from 'react-ga';

const Cart: React.FC<any> = () => {
	const { cartSize, getCheckout, clearCart, proceedToPurchase } =
		useShopify();
	const { address } = useAccount();

	const [lineItems, setLineItems] = useState<any[]>([]);
	const [subtotalPrice, setSubtotalPrice] = useState<string | undefined>();
	const [showWalletConfirmModal, setShowWalletConfirmModal] = useState(false);
	const [showNoWalletModal, setShowNoWalletModal] = useState(false);
	const [termsAndPolicyApproved, setTermsAndPolicyApproved] = useState(false);
	const [checkoutSuccess, setCheckoutSuccess] = useState(false);

	useEffect(() => {
		getCheckout().then((checkout) => {
			setLineItems(checkout.lineItems);
			setSubtotalPrice(
				// @ts-ignore
				`$${parseInt(checkout.subtotalPrice?.amount).toLocaleString(
					undefined,
					{
						minimumFractionDigits: 2,
					}
				)}`
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getCheckout, cartSize]); // cartSize is needed to force this memo to refresh when the cart is changed

	const onFocus = useCallback(() => {
		getCheckout().then((checkout) => {
			// if this is a value, the user successfully checked out
			if (checkout.completedAt) {
				// show a success message
				// clear the checkout
				clearCart();
				setCheckoutSuccess(true);
			}
		});
	}, [getCheckout, clearCart]);

	useEffect(() => {
		window.addEventListener('focus', onFocus);

		return () => {
			window.removeEventListener('focus', onFocus);
		};
	}, [onFocus]);

	const onCheckoutPressed = useCallback(async () => {
		try {
			proceedToPurchase(await getCheckout());
		} catch (err: any) {
			console.error(err);
			ReactGA.exception({
				description: err.toString(),
			});
		}
	}, [proceedToPurchase, getCheckout]);

	return (
		<div className="cart">
			<div className="container" lang="en">
				{!checkoutSuccess ? (
					<>
						<h1>YOUR CART</h1>
						{lineItems.length ? (
							<>
								<div className="cart-line-item-container">
									{lineItems.map((lineItem, i) => {
										return (
											<CartLineItem
												key={i}
												lineItem={lineItem}
											/>
										);
									})}
								</div>
								<div className="d-flex flex-row justify-content-between align-items-center">
									<h3>SUBTOTAL</h3>
									<p className="cart-subtotal-items">
										{'('}
										{cartSize}
										{` ${
											cartSize === 1 ? 'item' : 'items'
										}`}
										{') '}
										{subtotalPrice}
									</p>
								</div>
								<div
									className={`d-flex ${
										isMobile
											? 'flex-column'
											: 'flex-row align-items-center'
									} w-full justify-content-end  mt-4`}
								>
									{address ? (
										<div
											className={`d-flex ${
												isMobile
													? 'flex-column'
													: 'flex-row align-items-center'
											} `}
										>
											<p className="keep-whitespace">
												Airdrop Wallet Address:{' '}
												<b
													style={{
														overflowWrap:
															'break-word',
													}}
												>
													{address}
												</b>{' '}
											</p>
											<LinkConnectButton
												showAccount={false}
											/>
										</div>
									) : (
										<div
											className={`d-flex ${
												isMobile
													? 'flex-column'
													: 'flex-row align-items-center'
											} `}
										>
											<p className="keep-whitespace">
												After checkout, claim
												instructions for your NFT will
												arrive with the purchase via
												mail, or{' '}
											</p>
											<LinkConnectButton
												showAccount={false}
											/>
										</div>
									)}
								</div>
								<div className="d-flex flex-column align-items-end">
									<div className="d-flex flex-row mt-4">
										<Form.Check
											type="checkbox"
											label=""
											checked={termsAndPolicyApproved}
											onChange={(e) => {
												setTermsAndPolicyApproved(
													e.target.checked
												);
											}}
										/>
										<p>
											I agree to the Endstate{' '}
											<a
												href="https://www.endstate.io/terms"
												target="_blank"
												rel="noreferrer"
											>
												Terms
											</a>{' '}
											and{' '}
											<a
												href="https://www.endstate.io/privacy"
												target="_blank"
												rel="noreferrer"
											>
												Privacy Policy
											</a>
										</p>
									</div>
									<div
										className={`mt-2 ${
											isMobile ? 'w-full' : ''
										}`}
									>
										<Button
											className={`${
												isMobile ? 'w-full' : ''
											}`}
											onClick={onCheckoutPressed}
											disabled={!termsAndPolicyApproved}
										>
											PROCEED TO CHECKOUT
										</Button>
									</div>
								</div>
							</>
						) : (
							<>
								<h3 className="mt-4">Your cart is empty</h3>
							</>
						)}
					</>
				) : (
					<>
						<h1 className="mt-4">Thank you!</h1>
						<p>
							You will recieve an email shortly with your order
							confirmation.
						</p>
					</>
				)}
			</div>
			<WalletConfirmModal
				show={showWalletConfirmModal}
				onHide={() => {
					setShowWalletConfirmModal(false);
				}}
			/>
			<NoWalletModal
				show={showNoWalletModal}
				onHide={() => {
					setShowNoWalletModal(false);
				}}
			/>
		</div>
	);
};

export default Cart;
