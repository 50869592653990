import './BCNILDropDetails.scss';

import React, { useEffect, useState } from 'react';
import { Button, Carousel, Form } from 'react-bootstrap';
import useShopify from '../../hooks/useShopify';
import PerksSection from '../../components/collabs/PerksSection';
import AttributesSection from '../../components/collabs/AttributesSection';
import { isMobile } from 'react-device-detect';
import { AddedToCart } from '../../components/cart/AddedToCart';

const unavailableText = process.env.REACT_APP_NEW_HEIGHTS_MINTING_OPEN
	? 'SOLD OUT'
	: 'SOON';

const BCNILDropDetails: React.FC<any> = ({ dropContent }) => {
	const {
		sneakerAttributes,
		portraitAttributes,
		sneakerPerks,
		portraitPerks,
		nftData,
	} = dropContent;
	const { addProductToCart, getProductInfo } = useShopify();

	const [selectedVariant, setSelectedVariant] = useState<any>();
	const [sizeVariants, setSizeVariants] = useState<any[]>([]);
	const [sneakersLoading, setSneakersLoading] = useState<boolean>(false);
	const [sneakersAvailable, setSneakersAvailable] = useState<boolean>(false);
	const [sneakerImages, setSneakerImages] = useState<any[]>([]);
	const [portraitLoading, setPortraitLoading] = useState<boolean>(false);
	const [portraitAvailable, setPortraitAvailable] = useState<boolean>(false);
	const [portraitImage, setPortraitImage] = useState<any>();

	const [showAddedToCart, setShowAddedToCart] = useState<boolean>(false);

	const localSneakerPerks = { ...sneakerPerks };
	const localPortraitPerks = { ...portraitPerks };

	Object.keys(localSneakerPerks).forEach((key: string) => {
		localSneakerPerks[key] = localSneakerPerks[key].map((perk: string) => {
			return perk.replaceAll('{athlete}', nftData.name);
		});
	});

	Object.keys(localPortraitPerks).forEach((key: string) => {
		localPortraitPerks[key] = localPortraitPerks[key].map(
			(perk: string) => {
				return perk.replaceAll('{athlete}', nftData.name);
			}
		);
	});

	useEffect(() => {
		(async () => {
			setSneakersLoading(true);
			const productInfo = (await getProductInfo(
				nftData.productId
			)) as any;

			setSizeVariants(productInfo.variants);
			const firstVariant =
				productInfo.variants.find((variant: any) => {
					return variant.available;
				}) || productInfo.variants[0];

			setSelectedVariant(firstVariant.id);

			setSneakerImages(productInfo.images);
			setSneakersLoading(false);
			setSneakersAvailable(
				process.env.REACT_APP_NEW_HEIGHTS_MINTING_OPEN &&
					productInfo.availableForSale
			);
		})();

		(async () => {
			setPortraitLoading(true);
			const productInfo = (await getProductInfo(
				nftData.extras.nftOnly.productId
			)) as any;
			setPortraitLoading(false);
			setPortraitImage(productInfo.images[0]);
			setPortraitAvailable(
				process.env.REACT_APP_NEW_HEIGHTS_MINTING_OPEN &&
					productInfo.availableForSale
			);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="bc-nil">
			<div className="bc-nil-header">
				<img
					className="bc-nil-image"
					src={isMobile ? nftData.image : nftData.heroImage}
					alt={nftData.imageAlt}
				/>
				<div className="bc-nil-header-description">
					<h1>{nftData.name}</h1>
					<h2 className="mt-3">{nftData.extras?.quote}</h2>
					<p className="mt-3">{nftData.description}</p>
				</div>
			</div>

			<div className="bc-nil-banner"></div>

			<div className="bc-nil-product-grid">
				<div className="bc-nil-product-description">
					<h1 className="mb-0">SNEAKER</h1>
					<p>{nftData.extras?.sneakerDescription}</p>
					<div
						className={`d-flex ${
							isMobile ? 'flex-column' : 'flex-row'
						} mt-4`}
					>
						<Form.Select
							className="size-dropdown"
							value={selectedVariant}
							onChange={(e: any) => {
								setSelectedVariant(e.target.value);
							}}
							disabled={sneakersLoading}
						>
							{sizeVariants.map((variant: any, i: number) => (
								<option
									value={variant.id}
									key={i}
									disabled={!variant.available}
								>
									{variant.title}
								</option>
							))}
						</Form.Select>
						<Button
							className="bc-nil-purchase"
							onClick={() => {
								const sizeVariant = sizeVariants.find(
									(variant: any) => {
										return variant.id === selectedVariant;
									}
								);

								if (!sizeVariant) {
									return;
								}

								setShowAddedToCart(false);
								setSneakersLoading(true);
								addProductToCart(
									sizeVariant.id.replace(
										'gid://shopify/ProductVariant/',
										''
									),
									sizeVariant.title
								)
									.then(() => {
										setShowAddedToCart(true);
									})
									.finally(() => {
										setSneakersLoading(false);
									});
							}}
							disabled={!sneakersAvailable || sneakersLoading}
						>
							{sneakersLoading
								? 'LOADING'
								: sneakersAvailable
								? 'ADD TO CART $225'
								: unavailableText}
						</Button>
					</div>
					<div className="mt-4">
						<PerksSection perks={localSneakerPerks} />
					</div>

					<AttributesSection attributes={sneakerAttributes} />
				</div>
				<Carousel>
					{sneakerImages.map((image: any, i: number) => {
						return (
							<Carousel.Item key={i}>
								<img
									className="bc-nil-product-image"
									src={image.src}
									alt={image.altText}
								/>
							</Carousel.Item>
						);
					})}
				</Carousel>
			</div>

			<div className="bc-nil-product-grid">
				<img
					className="bc-nil-image"
					src={portraitImage?.src}
					alt={portraitImage?.altText}
				/>
				<div className="bc-nil-product-description">
					<h1 className="mb-0">SIGNED PORTRAIT</h1>
					<p>{nftData.extras?.portraitDescription}</p>
					<div className="d-flex flex-row mt-4">
						<Button
							className="bc-nil-purchase"
							onClick={() => {
								if (nftData.extras) {
									setShowAddedToCart(false);
									setPortraitLoading(true);
									addProductToCart(
										nftData.extras.nftOnly.variantId
									)
										.then(() => {
											setShowAddedToCart(true);
										})
										.finally(() => {
											setPortraitLoading(false);
										});
								}
							}}
							disabled={!portraitAvailable || portraitLoading}
						>
							{portraitLoading
								? 'LOADING'
								: portraitAvailable
								? 'ADD TO CART $50'
								: unavailableText}
						</Button>
					</div>
					<div className="mt-4">
						<PerksSection perks={localPortraitPerks} />
					</div>

					<AttributesSection attributes={portraitAttributes} />
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<div className="bc-nil-charity">
					<h2>
						A portion of all proceeds will be donated to{' '}
						{nftData.extras.charityName}
					</h2>
					<p>{nftData.extras.charityDescription}</p>
				</div>
			</div>
			{showAddedToCart && (
				<AddedToCart onHide={() => setShowAddedToCart(false)} />
			)}
		</div>
	);
};

export default BCNILDropDetails;
