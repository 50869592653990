import './PerksSection.scss';

import React, { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Dropdown } from 'react-bootstrap';

const PerksSection: React.FC<any> = ({
	perks,
}: {
	perks: { [key: string]: string[] };
}) => {
	const [selectedPerkTab, setSelectedPerkTab] = useState('Physical');

	const getPerksContent = () => {
		const selectedPerks = perks[selectedPerkTab.toLowerCase()];

		return (
			<div className="perks-bullet-list">
				{selectedPerks.map((p, i) => (
					<div className="perks-bullet-item" key={i}>
						{p}
					</div>
				))}
			</div>
		);
	};

	return (
		<div className="perks">
			<div className="perks-tabs">
				<BrowserView style={{ width: '100%', display: 'flex' }}>
					<div
						className={`perks-tab ${
							selectedPerkTab === 'Physical' &&
							'perks-tab-selected'
						}`}
						onClick={() => setSelectedPerkTab('Physical')}
					>
						<p>Physical</p>
					</div>
					<div
						className={`perks-tab ${
							selectedPerkTab === 'Digital'
								? 'perks-tab-selected'
								: ''
						}`}
						onClick={() => setSelectedPerkTab('Digital')}
					>
						<p>Digital</p>
					</div>
					<div
						className={`perks-tab ${
							selectedPerkTab === 'Experiential' &&
							'perks-tab-selected'
						}`}
						onClick={() => setSelectedPerkTab('Experiential')}
					>
						<p>Experiential</p>
					</div>
				</BrowserView>
				<MobileView style={{ width: '100%' }}>
					<Dropdown
						focusFirstItemOnShow={true}
						onSelect={(eventKey: any) => {
							setSelectedPerkTab(eventKey);
						}}
					>
						<Dropdown.Toggle variant="width" id="dropdown-basic">
							{selectedPerkTab}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item eventKey="Digital">
								Digital
							</Dropdown.Item>
							<Dropdown.Item eventKey="Physical">
								Physical
							</Dropdown.Item>
							<Dropdown.Item eventKey="Experiential">
								Experiential
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</MobileView>
			</div>
			<div className="perks-content">{getPerksContent()}</div>
		</div>
	);
};

export default PerksSection;
