import './Drop2Overview.scss';

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import collateralHeroPose from '../../assets/images/drop2/hero-poses/Collateral.png';
import emissaryHeroPose from '../../assets/images/drop2/hero-poses/Emissary.png';
import redactedHeroPose from '../../assets/images/drop2/hero-poses/Redacted.png';
import watergateHeroPose from '../../assets/images/drop2/hero-poses/Watergate.png';
import rarity6Mp4 from '../../assets/images/drop2/rarity6.mp4';
// import { initDrop2 } from "../../blockchain/contracts";
import Drop2MintButton from '../../components/mint/Drop2MintButton';
import moment from 'moment';

const colorwayOverviewContent: { [key: string]: any } = {
	redacted: {
		id: 0,
		title: 'REDACTED',
		subtitle: 'White Out + \nBlack Ink',
		nftsRemaining: 250,
		price: '0.3 ETH',
		status: 'Closed',
		image: {
			src: redactedHeroPose,
			alt: 'Hero pose of the Redacted shoes',
		},
	},
	watergate: {
		id: 1,
		title: 'WATERGATE',
		subtitle: 'Ocean Blue + \nCoral Blue',
		nftsRemaining: 250,
		price: '0.3 ETH',
		status: 'Closed',
		image: {
			src: watergateHeroPose,
			alt: 'Hero pose of the Watergate shoes',
		},
	},
	collateral: {
		id: 2,
		title: 'COLLATERAL',
		subtitle: 'Grey Zone + \nDeep Red',
		nftsRemaining: 250,
		price: '0.3 ETH',
		status: 'Closed',
		image: {
			src: collateralHeroPose,
			alt: 'Hero pose of the Collateral shoes',
		},
	},
	emissary: {
		id: 3,
		title: 'EMISSARY',
		subtitle: 'Marine + \nImperial',
		nftsRemaining: 250,
		price: '0.3 ETH',
		status: 'Closed',
		image: {
			src: emissaryHeroPose,
			alt: 'Hero pose of the Emissary shoes',
		},
	},
};

const maxNFTs = 250;

const Drop2Overview: React.FC<any> = () => {
	const [unclaimedSupplyRedacted, setUnclaimedSupplyRedacted] =
		useState<any>();
	const [unclaimedSupplyWatergate, setUnclaimedSupplyWatergate] =
		useState<any>();
	const [unclaimedSupplyCollateral, setUnclaimedSupplyCollateral] =
		useState<any>();
	const [unclaimedSupplyEmissary, setUnclaimedSupplyEmissary] =
		useState<any>();

	useEffect(() => {
		(async () => {
			// const dv = await initDrop2();
			// setUnclaimedSupplyRedacted(250 - (await dv.variationTotalSupply(0)));
			// setUnclaimedSupplyWatergate(250 - (await dv.variationTotalSupply(1)));
			// setUnclaimedSupplyCollateral(250 - (await dv.variationTotalSupply(2)));
			// setUnclaimedSupplyEmissary(250 - (await dv.variationTotalSupply(3)));
		})();
	}, []);

	const getColorwayOverview = (
		colorway: string,
		index: number,
		unclaimedSupply: number
	) => {
		const colorwayContent = colorwayOverviewContent[colorway];

		return (
			<div
				className={`drop2-overview ${
					index % 2 === 0
						? 'drop2-overview-rtl'
						: 'drop2-overview-ltr'
				}`}
			>
				<div className="drop2-overview-colorway">
					<div>
						<h1>{colorwayContent.title}</h1>
						<p>{colorwayContent.subtitle}</p>
						<div className="drop2-overview-mint-button-container">
							<span className="drop2-overview-mint-button-parent">
								<Drop2MintButton
									className="drop2-overview-mint-button"
									colorway={colorway}
									buttonText="MINT NOW"
									errorBelow
									dontModifyParams
								/>
							</span>
							<a href={`drop2/mint?drop2_colorway=${colorway}`}>
								<Button className="drop2-overview-mint-button-outline">
									MORE INFO
								</Button>
							</a>
						</div>
					</div>
					<div className="drop2-overview-colorway-details">
						<div className="drop2-overview-colorway-details-item">
							<p>{colorwayContent.price}</p>
							<h2>Price</h2>
						</div>
						<div className="drop2-overview-colorway-details-item">
							<p>{colorwayContent.status}</p>
							<h2>Mint</h2>
						</div>
						<div className="drop2-overview-colorway-details-item"></div>
					</div>
				</div>
				<img
					className="drop2-overview-image"
					src={colorwayContent.image.src}
					alt={colorwayContent.image.alt}
				/>
			</div>
		);
	};

	return (
		<div className="drop2-overview">
			<div className="drop2-overview drop2-overview-ltr">
				<div className="drop2-overview-statecraft">
					<h1>DROP 2: STATECRAFT</h1>
					<p>
						Introducing Drop 2: Statecraft – Endstate’s most
						ambitious drop to date. It’s not just an epic sneaker
						release, it's the first step in your journey to the
						Endstate: a future where all objects of value in the
						physical world have a digital counterpart.
					</p>
					<p>
						Each NFT is redeemable for a premium pair of sneakers
						and the two are definitively linked via NFC chip. Drop
						2: Statecraft NFTs feature variable attributes across
						six tiers of rarity. The lace types and accessories
						contained in your NFT transcend the digital state and
						are reflected on the physical sneakers you receive.
					</p>
					<p>
						The Statecraft silhouette is made in the USA and
						designed from the sole up by Endstate’s world renowned
						designers David Filar (lead design) and Stephanie Howard
						(design direction). The silhouette boasts asymmetric
						features, bespoke tongue construction, and cut-and-sew
						leather paneling. This is a classic in the making.
					</p>
				</div>
				<video
					className="drop2-overview-image"
					src={rarity6Mp4}
					loop
					autoPlay
					muted
					playsInline
				/>
			</div>
			<div className="drop2-overview-ticker">
				<p>PHYSICAL SNEAKERS</p>
				<p>•</p>
				<p>NFT</p>
				<p>•</p>
				<p>4 COLORWAYS</p>
				<p>•</p>
				<p>1000 NFTs</p>
				<p>•</p>
				<p>PHYSICAL AND DIGITAL RARITIES</p>
			</div>
			{getColorwayOverview('redacted', 0, unclaimedSupplyRedacted)}
			{getColorwayOverview('watergate', 1, unclaimedSupplyWatergate)}
			{getColorwayOverview('collateral', 2, unclaimedSupplyCollateral)}
			{getColorwayOverview('emissary', 3, unclaimedSupplyEmissary)}
			<div className="drop2-overview-howitworks">
				<h1>HOW IT WORKS</h1>
				<div className="drop2-overview-howitworks-steps">
					<div className="drop2-overview-howitworks-step">
						<h2>1</h2>
						<h3>Mint Your Drop 2 NFT</h3>
						<p>
							• Mint your Drop 2 NFT directly on the Endstate site
							at <a href="./drop2">mint.endstate.io/drop2</a>
						</p>
						<p>
							• Endstate supports Paper.xyz, which means you can
							mint and pay for your NFT via credit card, BTC, or
							ETH
						</p>
						<p>
							Click <a href="https://paper.xyz/">here</a> for
							additional information on Paper.xyz
						</p>
					</div>
					<div className="drop2-overview-howitworks-step">
						<h2>2</h2>
						<h3>State Change</h3>
						<p>
							• In order to take receipt of your physical Drop 2
							sneakers, you will need to participate in the Drop 2
							State Change event
						</p>
						<p className="indented">
							◦ Follow all Endstate socials to be alerted when the
							Drop 2 State Change event goes live
						</p>
						<p>
							• Connect your Ethereum wallet with your Drop 2 NFT
							to the Endstate site
						</p>
						<p>
							• Complete the State Change process to redeem your
							Drop 2 NFT for your physical Drop 2 sneakers
						</p>
						<p>
							• Your NFT is not burned during State Change - it is
							your key to the Endstate
						</p>
					</div>
					<div className="drop2-overview-howitworks-step">
						<h2>3</h2>
						<h3>Connect to the Endstate Discord</h3>
						<p>
							• Click{' '}
							<a href="https://discord.gg/2bHG8uukxV">here</a> to
							join the Endstate Discord
						</p>
						<p>
							• Connect the Ethereum wallet with your Drop 2 NFT
							to the Endstate Discord
						</p>
						<p>
							• Complete the verification process within the
							Endstate server to join the holders-only State
							Department channel
						</p>
					</div>
				</div>
			</div>
			<div className="drop2-overview-drop0">
				<div className="drop2-overview-drop0-content">
					<div className="drop2-overview-perks">
						<div className="drop2-overview-perk">
							<h2>Physical</h2>
							<p>• One pair of Drop 2: Statecraft sneakers</p>
							<p>• Italian leather upper</p>
							<p>
								• Lace types and accessories dependent on your
								NFT’s rarity
							</p>
							<p>
								• Military-grade polyurethane midsole and solid
								rubber outsole
							</p>
							<p>• Made in the USA with imported materials</p>
						</div>
						<div className="drop2-overview-perk">
							<h2>Digital</h2>
							<p>
								• 3D rendering of your Drop 2 sneakers in the
								Endstate metaverse environment
							</p>
							<p>• One of six tiers of rarities</p>
							<p>
								• One-to-one accessory matching with your
								Statecraft sneakers
							</p>
							<p>• Future metaverse integrations</p>
						</div>
						<div className="drop2-overview-perk">
							<h2>Experiential</h2>
							<p>
								• Exclusive access to token-gated events hosted
								by Endstate
							</p>
							<p>
								• Sneak peeks and input on future designs and
								collaborations
							</p>
							<p>
								• Entry into The State Department channel within
								the Endstate Discord
							</p>
							<p>
								• Authentication via NFC chip embedded in
								sneaker
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Drop2Overview;
