import { RefObject, useEffect, useMemo, useState } from 'react';

export default function useOnScreen(ref: RefObject<HTMLElement>) {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = useMemo(
		() =>
			new IntersectionObserver(
				([entry]) => setIntersecting(entry.isIntersecting),
				{ threshold: 0.65 }
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ref]
	);

	useEffect(() => {
		if (ref?.current) {
			observer.observe(ref.current);
		}
		return () => observer.disconnect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isIntersecting;
}
