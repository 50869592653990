const logEvent = async (eventName: string, params: any): Promise<any> => {
	// @ts-ignore
	window.gtag('event', eventName, params);
};

const logPurchaseEvent = (params: any) => {
	logEvent('purchase', params);
};

const logErrorEvent = (params: any) => {
	logEvent('error', params);
};

const setUserProperties = (props: any) => {
	// @ts-ignore
	window.gtag('set', 'user_properties', props);
};

export { logPurchaseEvent, logErrorEvent, logEvent, setUserProperties };
