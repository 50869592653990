import './Drop2Voucher.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { web3, BN } from '../../blockchain/web3Utils';
import {
	drop2VoucherContractAddress,
	// initBlitkick,
	// initDrop2Voucher,
} from '../../blockchain/contracts';
import { ethereum, isMetamaskAvailable } from '../../blockchain/metamask';
import { Button, Carousel, CarouselItem } from 'react-bootstrap';
import Web3 from 'web3';

import blackFlatout from '../../assets/images/flatout-black.jpg';
import blueFlatout from '../../assets/images/flatout-blue.jpg';
import greenFlatout from '../../assets/images/flatout-green.jpg';
import greyFlatout from '../../assets/images/flatout-grey.jpg';

import blackRender from '../../assets/images/drop-2-black.jpg';
import blueRender from '../../assets/images/drop-2-blue.jpg';
import greenRender from '../../assets/images/drop-2-green.jpg';
import greyRender from '../../assets/images/drop-2-grey.jpg';

import { FaEthereum } from 'react-icons/fa';

const colorWays: any = {
	redacted: {
		maxMintCount: 100,
		mintCount: 0,
		isSoldOut: false,
		id: 0,
		render: blackRender,
	},
	watergate: {
		maxMintCount: 100,
		mintCount: 0,
		isSoldOut: false,
		id: 1,
		render: blueRender,
	},
	redline: {
		maxMintCount: 100,
		mintCount: 0,
		isSoldOut: false,
		id: 2,
		render: greyRender,
	},
	decoy: {
		maxMintCount: 100,
		mintCount: 0,
		isSoldOut: false,
		id: 3,
		render: greenRender,
	},
};

const Drop2Voucher: React.FC<any> = () => {
	// const [isMintingOpen, setIsMintingOpen] = useState<boolean>(
	//   (process.env.REACT_APP_DROP_2_MINTING_OPEN || "false") === "true"
	// );
	const [colorWaysData, setColorWaysData] = useState<any | undefined>(
		undefined
	);
	const [price, setPrice] = useState<string>('0.3');
	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState(false);
	const [drop2Contract, setDrop2Contract] = useState<any>();
	const [blitkickContract, setBlitkickContract] = useState<any>();
	const [transactionHash, setTransactionHash] = useState<string>('');
	const [loading, setLoading] = useState(false);

	const [selectedColorway, setSelectedColorway] = useState<
		string | undefined
	>(undefined);
	const [purchasedColorway, setPurchasedColorway] = useState<string>('');

	useEffect(() => {
		// (async () => {
		// initialize the contract
		// const dv = await initDrop2Voucher();
		// setDrop2Contract(dv);
		// const bc = await initBlitkick();
		// setBlitkickContract(bc);
		// 	setPrice(Web3.utils.fromWei(await dv.price(), 'ether'));
		// 	if (dv && !colorWaysData) {
		// 		const newData = { ...colorWays };
		// 		newData['redacted'].mintCount = Web3.utils.toNumber(
		// 			await dv.colorwaySupply(0)
		// 		);
		// 		if (
		// 			newData['redacted'].mintCount >=
		// 			newData['redacted'].maxMintCount
		// 		) {
		// 			newData['redacted'].isSoldOut = true;
		// 		}
		// 		newData['watergate'].mintCount = Web3.utils.toNumber(
		// 			await dv.colorwaySupply(1)
		// 		);
		// 		if (
		// 			newData['watergate'].mintCount >=
		// 			newData['watergate'].maxMintCount
		// 		) {
		// 			newData['watergate'].isSoldOut = true;
		// 		}
		// 		newData['redline'].mintCount = Web3.utils.toNumber(
		// 			await dv.colorwaySupply(2)
		// 		);
		// 		if (
		// 			newData['redline'].mintCount >=
		// 			newData['redline'].maxMintCount
		// 		) {
		// 			newData['redline'].isSoldOut = true;
		// 		}
		// 		newData['decoy'].mintCount = Web3.utils.toNumber(
		// 			await dv.colorwaySupply(3)
		// 		);
		// 		if (
		// 			newData['decoy'].mintCount >= newData['decoy'].maxMintCount
		// 		) {
		// 			newData['decoy'].isSoldOut = true;
		// 		}
		// 		setColorWaysData(newData);
		// 	}
		// })();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const purchaseShoe = useCallback(
		async (colorway: any) => {
			if (loading || !isMetamaskAvailable || !colorWaysData) {
				return;
			}

			setError('');
			setLoading(true);
			try {
				// connect the wallet
				const accounts = await ethereum?.request({
					method: 'eth_requestAccounts',
				});
				let accountPubKey = '';
				if (accounts) {
					accountPubKey = accounts[0];
				}

				// check if the user has a blitkick
				const accountHasBlitkick =
					web3.utils.toNumber(
						await blitkickContract.balanceOf(accountPubKey)
					) > 0;

				if (accountHasBlitkick) {
					// call publicMint with the clickedColorway as a param
					const txn = drop2Contract.contract.methods.mint(
						colorWaysData[colorway].id,
						1
					);

					// calcualte the gas
					const value = new BN(Web3.utils.toWei(price));
					const gas = await txn.estimateGas({
						from: accountPubKey,
						value: Web3.utils.toWei(price),
					});

					// form the transaction parameters
					// @ts-ignore
					const transactionParameters = {
						to: drop2VoucherContractAddress, // Required except during contract publications.
						from: accountPubKey, // must match user's active address.
						value: web3.utils.numberToHex(value),
						gas: web3.utils.numberToHex(gas),
						data: txn.encodeABI(),
					};

					// request the transaction
					// const txHash = await ethereum?.request({
					// 	method: 'eth_sendTransaction',
					// 	params: [transactionParameters],
					// });

					// set the hash
					// setTransactionHash(txHash);
					setPurchasedColorway(colorway);

					// set the success state
					setSuccess(true);
				} else {
					setError(
						'Certificate minting is open for Blitkick holders only'
					);
				}
			} catch (e: any) {
				console.error(e);
			}
			setLoading(false);
		},
		[loading, price, drop2Contract, colorWaysData, blitkickContract]
	);

	const getMintButtonChildren = () => {
		if (isMetamaskAvailable) {
			return (
				<>
					MINT <FaEthereum /> 0.3
				</>
			);
		} else {
			return <>Use MetaMask mobile browser to mint</>;
		}
	};

	return (
		<div className="drop2-voucher" lang="en">
			<div className="file-container">
				<h1>TOP SECRET</h1>
				<p className="subtitle">SPECIAL AUTHORIZATION REQUIRED</p>
				<div className="copy">
					<p>
						Endstate’s Drop 2 operations have been disclosed to a
						select few.
					</p>
					<p>
						If you’re reading this, you’ve gathered the requisite
						intelligence required to mint a Drop 2 Certificate. This
						certificate entitles its holder to mint a Drop 2 NFT,
						redeemable for a pair of physical sneakers. Drop 2 is
						our largest mission yet and you’re among the first
						recruits.
					</p>
					<p>
						Your colorway will determine your place in the Endstate.
						Make sure to follow Endstate on all socials to be
						alerted when Drop 2 goes live.
					</p>
				</div>
				{colorWaysData && (
					<>
						{success ? (
							<div className="success-container">
								<h2>Certificate Acquired</h2>
								<p>Stay tuned for more info about redemption</p>
								<img
									className="success-image"
									alt="Successful render "
									src={
										colorWaysData[purchasedColorway].render
									}
								/>
								<a
									className="mint-address"
									target="_blank"
									rel="noreferrer"
									href={`https://etherscan.io/tx/${transactionHash}`}
								>
									View Transaction
								</a>
							</div>
						) : (
							<>
								{error.length > 0 && (
									<div className="error-text">{error}</div>
								)}
								<div className="colorways-container">
									{/*
									 *
									 * REDACTED
									 *
									 */}
									<div
										className={`colorways-item ${
											colorWaysData.redacted.isSoldOut
												? ''
												: 'clickable'
										}`}
										onMouseOver={() => {
											if (
												!colorWaysData.redacted
													.isSoldOut
											) {
												setSelectedColorway('redacted');
											}
										}}
										onMouseOut={() => {
											setSelectedColorway(undefined);
										}}
										onClick={() => {
											if (
												!colorWaysData.redacted
													.isSoldOut
											) {
												purchaseShoe('redacted');
											}
										}}
									>
										<img
											src={blackFlatout}
											alt="Drop 2 Black flatout"
										/>
										<div className="colorways-price">
											<Button
												disabled={
													colorWaysData.redacted
														.isSoldOut
												}
												variant={
													selectedColorway ===
													'redacted'
														? 'primary'
														: 'outline-primary'
												}
											>
												{!colorWaysData.redacted
													.isSoldOut ? (
													<>
														{getMintButtonChildren()}
													</>
												) : (
													<>SOLD OUT</>
												)}
											</Button>
										</div>
									</div>
									{/*
									 *
									 * WATERGATE
									 *
									 */}
									<div
										className={`colorways-item ${
											colorWaysData.watergate.isSoldOut
												? ''
												: 'clickable'
										}`}
										onMouseOver={() => {
											if (
												!colorWaysData.watergate
													.isSoldOut
											) {
												setSelectedColorway(
													'watergate'
												);
											}
										}}
										onMouseOut={() => {
											setSelectedColorway(undefined);
										}}
										onClick={() => {
											if (
												!colorWaysData.watergate
													.isSoldOut
											) {
												purchaseShoe('watergate');
											}
										}}
									>
										<img
											src={blueFlatout}
											alt="Drop 2 Blue flatout"
										/>
										<div className="colorways-price">
											<Button
												disabled={
													colorWaysData.watergate
														.isSoldOut
												}
												variant={
													selectedColorway ===
													'watergate'
														? 'primary'
														: 'outline-primary'
												}
											>
												{!colorWaysData.watergate
													.isSoldOut ? (
													<>
														{getMintButtonChildren()}
													</>
												) : (
													<>SOLD OUT</>
												)}
											</Button>
										</div>
									</div>
									{/*
									 *
									 * REDLINE
									 *
									 */}
									<div
										className={`colorways-item ${
											colorWaysData.redline.isSoldOut
												? ''
												: 'clickable'
										}`}
										onMouseOver={() => {
											if (
												!colorWaysData.redline.isSoldOut
											) {
												setSelectedColorway('redline');
											}
										}}
										onMouseOut={() => {
											setSelectedColorway(undefined);
										}}
										onClick={() => {
											if (
												!colorWaysData.redline.isSoldOut
											) {
												purchaseShoe('redline');
											}
										}}
									>
										<img
											src={greyFlatout}
											alt="Drop 2 Grey flatout"
										/>
										<div className="colorways-price">
											<Button
												disabled={
													colorWaysData.redline
														.isSoldOut
												}
												variant={
													selectedColorway ===
													'redline'
														? 'primary'
														: 'outline-primary'
												}
											>
												{!colorWaysData.redline
													.isSoldOut ? (
													<>
														{getMintButtonChildren()}
													</>
												) : (
													<>SOLD OUT</>
												)}
											</Button>
										</div>
									</div>
									{/*
									 *
									 * DECOY
									 *
									 */}
									<div
										className={`colorways-item ${
											colorWaysData.decoy.isSoldOut
												? ''
												: 'clickable'
										}`}
										onMouseOver={() => {
											if (
												!colorWaysData.decoy.isSoldOut
											) {
												setSelectedColorway('decoy');
											}
										}}
										onMouseOut={() => {
											setSelectedColorway(undefined);
										}}
										onClick={() => {
											if (
												!colorWaysData.decoy.isSoldOut
											) {
												purchaseShoe('decoy');
											}
										}}
									>
										<img
											src={greenFlatout}
											alt="Drop 2 Green flatout"
										/>
										<div className="colorways-price">
											<Button
												disabled={
													colorWaysData.decoy
														.isSoldOut
												}
												variant={
													selectedColorway === 'decoy'
														? 'primary'
														: 'outline-primary'
												}
											>
												{!colorWaysData.decoy
													.isSoldOut ? (
													<>
														{getMintButtonChildren()}
													</>
												) : (
													<>SOLD OUT</>
												)}
											</Button>
										</div>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</div>
			<div className="image-carousel-container">
				<div className="image-carousel">
					<Carousel variant="dark">
						<CarouselItem>
							<img
								className="image"
								alt="Drop 2 Black render"
								src={blackRender}
							/>
						</CarouselItem>
						<CarouselItem>
							<img
								className="image"
								alt="Drop 2 Blue render"
								src={blueRender}
							/>
						</CarouselItem>
						<CarouselItem>
							<img
								className="image"
								alt="Drop 2 Grey render"
								src={greyRender}
							/>
						</CarouselItem>
						<CarouselItem>
							<img
								className="image"
								alt="Drop 2 Green render"
								src={greenRender}
							/>
						</CarouselItem>
					</Carousel>
				</div>
			</div>
		</div>
	);
};

export default Drop2Voucher;
