import './Header.scss';

import React from 'react';

import logo from '../../assets/images/endstate-logo.png';
import { useLocation } from 'react-router-dom';
import { FaBars, FaShoppingBag } from 'react-icons/fa';
import useShopify from '../../hooks/useShopify';
import { BrowserView, isMobile, MobileOnlyView } from 'react-device-detect';
import { Dropdown } from 'react-bootstrap';

const Header: React.FC<any> = () => {
	const location = useLocation();
	// TODO this should not be hardcoded, we'll need to find a more elegant solution for this
	const overlay = location.pathname.includes('devontasmith');

	const { cartSize } = useShopify();

	return (
		<div className={`header ${overlay && 'header-overlay'}`}>
			{/* left */}
			<div className="section left">
				<MobileOnlyView>
					<div className="d-flex flex-column justift-content-center">
						<Dropdown
							drop="down"
							onSelect={(eventKey: any) => {
								if (eventKey === '1') {
									window.location.replace('/EndstateU');
								} else if (eventKey === '2') {
									window.location.replace('/DeVontaSmith');
								} else if (eventKey === '3') {
									window.location.replace('/Crowther');
								}
							}}
						>
							<Dropdown.Toggle variant="link" id="menu-dropdown">
								<FaBars color="#ffffff" size={24} />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item eventKey="1">
									Endstate U: New Heights
								</Dropdown.Item>
								<Dropdown.Item eventKey="2">
									DeVonta Smith
								</Dropdown.Item>
								<Dropdown.Item eventKey="3">
									Red Bandanna Sneaker
								</Dropdown.Item>

								{/* <Dropdown.Item eventKey="1">
									<center>
										{`Cart${
											cartSize > 0 ? ` (${cartSize})` : ''
										}`}
									</center>
								</Dropdown.Item> */}
								{/* <Dropdown.Item
									eventKey="2"
									as={LinkConnectButton}
									showAccount
									centered
								></Dropdown.Item> */}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</MobileOnlyView>
				<BrowserView>
					<a href="https://www.endstate.io">
						<img className="logo" src={logo} alt="Endstate" />
					</a>
				</BrowserView>
			</div>
			{/* center */}
			<div className="section center">
				<MobileOnlyView>
					<a href="https://www.endstate.io">
						<img className="logo" src={logo} alt="Endstate" />
					</a>
				</MobileOnlyView>
			</div>
			{/* right */}
			<div className="section right">
				{/* <MobileOnlyView></MobileOnlyView>
				<BrowserView> */}
				<div className="d-flex flex-row align-items-center">
					{cartSize > 0 && !isMobile && (
						<a className={`header-cart-link`} href="/cart">
							View Cart
						</a>
					)}
					<a
						className={`header-button`}
						style={{ marginLeft: 8 }}
						href="/cart"
					>
						<FaShoppingBag
							color="#ffffff"
							size={24}
							onClick={() => {}}
						/>
						{cartSize > 0 && (
							<p className="header-cart-size">{cartSize}</p>
						)}
					</a>

					{/* <button
						className="header-button"
						style={{ marginLeft: 24 }}
					>
						<ConnectButton
							accountStatus="address"
							showBalance={false}
						/>
					</button> */}
				</div>
				{/* </BrowserView> */}
			</div>
		</div>
	);
};

export default Header;
