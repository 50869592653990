import assign from 'deep-assign';

const baseApiUrl = process.env.REACT_APP_API_BASE_URL;

async function get<T>(path: string, options?: any): Promise<T> {
	return rawGet(`${baseApiUrl}${path}`, options);
}

async function rawGet<T>(path: string, options?: any): Promise<T> {
	return fetchWithDefaults(
		path,
		assign(
			{
				method: 'get',
			},
			options
		)
	);
}

async function post(path: string, options?: any): Promise<any> {
	return fetchWithDefaults(
		`${baseApiUrl}${path}`,
		assign(
			{
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'post',
			},
			options
		)
	);
}

async function postFile(path: string, options?: any): Promise<any> {
	return fetchWithDefaults(
		`${baseApiUrl}${path}`,
		assign(
			{
				method: 'post',
			},
			options
		)
	);
}

async function put(path: string, options?: any): Promise<any> {
	return fetchWithDefaults(
		`${baseApiUrl}${path}`,
		assign(
			{
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'put',
			},
			options
		)
	);
}

async function del(path: string, options?: any): Promise<any> {
	return fetchWithDefaults(
		`${baseApiUrl}${path}`,
		assign(
			{
				method: 'delete',
			},
			options
		)
	);
}

async function fetchWithDefaults(path: string, options?: any): Promise<any> {
	const response = await fetch(
		path,
		assign(
			{
				headers: {
					Accept: 'application/json',
				},
			},
			options
		)
	);
	switch (response.status) {
		case 204:
			return;
		case 400:
			throw new Error('Bad request');
		case 401:
			throw new Error('Unauthorized');
		case 500:
			throw {
				response: await response.json(),
				error: new Error(),
			};
		default:
			return response.json();
	}
}

export { del, get, rawGet, post, postFile, put };
