import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import './AddedToCart.scss';

export const AddedToCart = ({ onHide }: { onHide: () => any }) => {
	return (
		<div className="added-to-cart d-flex flex-column">
			<div className="close-button" onClick={() => onHide()}>
				<FaTimes color="#000000" size={16} />
			</div>
			<div className="d-flex flex-row align-items-center">
				<FaCheckCircle
					color="#4BB543"
					size={24}
					style={{ marginRight: '12px' }}
				/>
				Added to cart
			</div>
			<a href="/cart">View Cart</a>
		</div>
	);
};
