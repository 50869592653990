import './Drop2MintButton.scss';

import {
	PaperCheckout,
	PaperCheckoutDisplay,
} from '@paperxyz/react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import Web3 from 'web3';

import {
	drop2ContractAddress,
	// initDrop2,
	// initDrop2Allowlist,
	// initDrop2Voucher,
	// initDropZeroAllowlist,
	// initTungstenAllowlist,
} from '../../blockchain/contracts';
import { BN } from '../../blockchain/web3Utils';
import getDrop2PageContent from '../../drops/drop2PageContent';
import useEthereumWeb3 from '../../hooks/useEthereumWeb3';
import { getArweaveAssetData } from '../../services/ArweaveService';
import Drop2NFTModal from './Drop2NFTModal';
import { FaRegTimesCircle } from 'react-icons/fa';
import { rawGet } from '../../services/FetchService';
import {
	logErrorEvent,
	logEvent,
	logPurchaseEvent,
} from '../../services/AnalyticsService';

const Drop2MintButton: React.FC<any> = ({
	colorway,
	className,
	buttonText,
	fullWidth = false,
	errorBelow = false,
	dontModifyParams = false,
}: {
	colorway: string;
	className: string;
	buttonText: string | undefined;
	fullWidth: boolean;
	errorBelow: boolean;
	dontModifyParams: boolean;
}) => {
	const [searchParams, setSearchParams] = useSearchParams({});

	const fpm = searchParams.get('fpm') === 'true';
	const fsm = searchParams.get('fsm') === 'true';

	const [transactionHash, setTransactionHash] = useState(
		searchParams.get('txn') || ''
	);
	const [nftId, setNftId] = useState(
		parseInt(searchParams.get('id') || '-1')
	);

	const [drop2Contract, setDrop2Contract] = useState<any>();
	const [drop2AllowlistContract, setDrop2AllowlistContract] = useState<any>();
	const [voucherContract, setVoucherContract] = useState<any>();
	const [dropZeroALContract, setDropZeroALContract] = useState<any>();
	const [tungstenALContract, setTungstenALContract] = useState<any>();

	const [price, setPrice] = useState<string>('0.3');
	const [allowListMintOpen, setAllowListMintOpen] = useState<boolean>(false);
	const [specialMintOpen, setSpecialMintOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const [isSoldOut, setIsSoldOut] = useState<boolean>(false);
	const [nftMetadata, setNftMetadata] = useState<any>(undefined);

	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [successModalAlreadyShown, setSuccessModalAlreadyShown] =
		useState<boolean>(false);

	const [hasVoucher, setHasVoucher] = useState<boolean>(false);
	const [hasDropZero, setHasDropZero] = useState<boolean>(false);
	const [hasTungsten, setHasTungsten] = useState<boolean>(false);

	const [showExtraMintOptions, setShowExtraMintOptions] =
		useState<boolean>(false);

	const { requestConnectWallet, ethereum, connectedWallet, web3 } =
		useEthereumWeb3();

	const { paperId, id: colorwayId } = getDrop2PageContent(colorway);

	const getSearchParams = useCallback((): any[] => {
		return [['drop2_colorway', colorway]];
	}, [colorway]);

	/*
	 * First use effect to run on mount
	 */
	useEffect(() => {
		(async () => {
			if (!connectedWallet) {
				requestConnectWallet();
			}

			// initialize the contract
			// const dv = await initDrop2();
			// setDrop2Contract(dv);
			// const al = await initDrop2Allowlist();
			// setDrop2AllowlistContract(al);
			// const vc = await initDrop2Voucher();
			// setVoucherContract(vc);

			// const dzal = await initDropZeroAllowlist();
			// setDropZeroALContract(dzal);

			// const tual = await initTungstenAllowlist();
			// setTungstenALContract(tual);

			// setAllowListMintOpen(await dv.allowListActive());
			// setSpecialMintOpen(fsm || (await dv.specialMintActive()));
			setSpecialMintOpen(false);
			// setPrice(Web3.utils.fromWei(await dv.price(colorwayId), 'ether'));

			// const unclaimedSupply = await dv.unclaimedSupply(colorwayId);
			// setIsSoldOut(unclaimedSupply === 0);
			setIsSoldOut(false);

			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		(async () => {
			if (connectedWallet) {
				setHasVoucher(
					(await voucherContract?.balanceOf(
						connectedWallet,
						colorwayId
					)) > 0
				);
				setHasDropZero(
					true || (await dropZeroALContract?.onList(connectedWallet))
				);
				setHasTungsten(
					true || (await tungstenALContract?.onList(connectedWallet))
				);
			}
		})();
	}, [
		colorwayId,
		connectedWallet,
		dropZeroALContract,
		tungstenALContract,
		voucherContract,
	]);

	/*
	 * Runs when there is a transaction hash but no wallet. We need the wallet
	 * for when we determine the transaction complete
	 */
	useEffect(() => {
		if (transactionHash.length && !connectedWallet) {
			requestConnectWallet();
		}
	}, [transactionHash, connectedWallet, requestConnectWallet]);

	/*
	 * Runs when there is a transaction hash and everything is set up
	 * When the transaction is complete, the txn will be replaced by an
	 * ID and then the success modal will appear
	 */
	useEffect(() => {
		if (
			transactionHash.length &&
			drop2Contract &&
			web3 &&
			connectedWallet
		) {
			const interval = setInterval(() => {
				web3.eth
					.getTransactionReceipt(transactionHash)
					.then(async (result: any) => {
						if (result) {
							const balance = await drop2Contract.balanceOf(
								connectedWallet
							);
							const nftId =
								await drop2Contract.tokenOfOwnerByIndex(
									connectedWallet,
									balance - 1
								);
							if (!dontModifyParams) {
								setSearchParams([
									...getSearchParams(),
									['id', nftId],
								]);
							}
							setNftId(nftId.toString());
							setTransactionHash('');
							setSuccessModalAlreadyShown(false);
						}
					})
					.catch((e: any) => {
						setSearchParams([...getSearchParams()]);
						logErrorEvent({
							action: 'Fetch id from txHash',
							message: e.toString(),
						});
					});
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [
		transactionHash,
		drop2Contract,
		connectedWallet,
		web3,
		setSearchParams,
		colorway,
		getSearchParams,
		dontModifyParams,
	]);

	/*
	 * Runs when there is an id. Get the metadata then show the success modal
	 */
	useEffect(() => {
		if (nftId >= 0 && drop2Contract && !successModalAlreadyShown) {
			drop2Contract.tokenURI(nftId).then(async (metadataUri: any) => {
				const metadata = await getArweaveAssetData(metadataUri);

				setNftMetadata({
					nftAnimationUri: metadata.animation_url,
					id: nftId,
					name: metadata.name,
					attributes: {
						embellishment: metadata.attributes.find(
							(attr: any) => attr.trait_type === 'Embellishment'
						).value,
						pillar: metadata.attributes.find(
							(attr: any) => attr.trait_type === 'Pillar'
						).value,
						environment: metadata.attributes.find(
							(attr: any) => attr.trait_type === 'Environment'
						).value,
					},
				});
				setShowSuccessModal(true);
				setSuccessModalAlreadyShown(true);
				logEvent('show_mint_success', {
					nft_id: nftId,
				});
			});
		}
	}, [nftId, drop2Contract, successModalAlreadyShown]);

	const mintNftRaw = useCallback(
		async (accountAddress: string) => {
			try {
				if (!ethereum) {
					return;
				}

				const txn = await drop2Contract.contract.methods.mint(
					colorwayId
				);

				// calcualte the gas
				const value = new BN(Web3.utils.toWei(price));
				const gas = await txn.estimateGas({
					from: accountAddress,
					value: Web3.utils.toWei(price),
				});

				// Allowable: you have reached your allowable mint limit

				// form the transaction parameters
				// @ts-ignore
				const transactionParameters = {
					to: drop2ContractAddress, // Required except during contract publications.
					from: accountAddress, // must match user's active address.
					value: Web3.utils.numberToHex(value),
					gas: Web3.utils.numberToHex(gas),
					data: txn.encodeABI(),
				};

				// request the transaction
				// const txHash = await ethereum.request({
				// 	method: 'eth_sendTransaction',
				// 	params: [transactionParameters],
				// });

				// fire GA event
				// logPurchaseEvent({
				// 	colorway: colorway,
				// 	method: 'wallet mint',
				// 	transaction_hash: txHash,
				// 	gas: gas,
				// 	price: price,
				// 	currency: 'ETH',
				// 	address: accountAddress,
				// });

				// set the hash
				// if (!dontModifyParams) {
				// 	setSearchParams([...getSearchParams(), ['txn', txHash]]);
				// }
				// setTransactionHash(txHash);
			} catch (e: any) {
				if (e?.toString().includes('allowable mint limit')) {
					setError('Mint limit reached');
				} else if (
					e?.toString().includes('insufficient funds for gas')
				) {
					setError('Insufficient funds');
				} else {
					setError('Failed to mint');
				}
				logErrorEvent({
					action: 'wallet mint',
					message: e?.toString(),
				});
				setModalOpen(false);
			}
			setLoading(false);
		},
		[
			drop2Contract,
			colorwayId,
			price,
			ethereum,
			colorway,
			dontModifyParams,
			setSearchParams,
			getSearchParams,
		]
	);

	const mintMftVoucher = useCallback(
		async (accountAddress: string) => {
			if (drop2Contract) {
				const txn = await drop2Contract.contract.methods.voucherMint(
					colorwayId
				);

				// calcualte the gas
				const gas = await txn.estimateGas({
					from: accountAddress,
				});

				// Allowable: you have reached your allowable mint limit

				// form the transaction parameters
				// @ts-ignore
				const transactionParameters = {
					to: drop2ContractAddress, // Required except during contract publications.
					from: accountAddress, // must match user's active address.
					gas: Web3.utils.numberToHex(gas),
					data: txn.encodeABI(),
				};

				// request the transaction
				// const txHash = await ethereum.request({
				// 	method: 'eth_sendTransaction',
				// 	params: [transactionParameters],
				// });

				// set the hash
				// if (!dontModifyParams) {
				// 	setSearchParams([...getSearchParams(), ['txn', txHash]]);
				// }
				// setTransactionHash(txHash);

				// fire GA event
				// logPurchaseEvent({
				// 	colorway: colorway,
				// 	method: 'voucher mint',
				// 	transaction_hash: txHash,
				// 	gas: gas,
				// 	price: price,
				// 	currency: 'ETH',
				// 	address: accountAddress,
				// });
			}
		},
		[
			colorway,
			colorwayId,
			dontModifyParams,
			drop2Contract,
			ethereum,
			getSearchParams,
			price,
			setSearchParams,
		]
	);

	const mintDrop0 = useCallback(
		async (accountAddress: string) => {
			try {
				if (drop2Contract) {
					const txn =
						await drop2Contract.contract.methods.dropZeroMint(
							colorwayId
						);
					// calcualte the gas
					const gas = await txn.estimateGas({
						from: accountAddress,
					});
					// form the transaction parameters
					// @ts-ignore
					const transactionParameters = {
						to: drop2ContractAddress, // Required except during contract publications.
						from: accountAddress, // must match user's active address.
						gas: Web3.utils.numberToHex(gas),
						data: txn.encodeABI(),
					};
					// request the transaction
					// const txHash = await ethereum.request({
					// 	method: 'eth_sendTransaction',
					// 	params: [transactionParameters],
					// });
					// set the hash
					// if (!dontModifyParams) {
					// 	setSearchParams([
					// 		...getSearchParams(),
					// 		['txn', txHash],
					// 	]);
					// }
					// setTransactionHash(txHash);
					// // fire GA event
					// logPurchaseEvent({
					// 	colorway: colorway,
					// 	method: 'drop0 mint',
					// 	transaction_hash: txHash,
					// 	gas: gas,
					// 	price: price,
					// 	currency: 'ETH',
					// 	address: accountAddress,
					// });
				}
			} catch (e: any) {
				if (e?.toString().includes('allowable mint limit')) {
					setError('Mint limit reached');
				} else if (
					e?.toString().includes('insufficient funds for gas')
				) {
					setError('Insufficient funds');
				} else {
					setError('Failed to mint');
				}
				logErrorEvent({ action: 'drop 0', message: e?.toString() });
			}
			setModalOpen(false);
		},
		[
			colorway,
			colorwayId,
			dontModifyParams,
			drop2Contract,
			ethereum,
			getSearchParams,
			price,
			setSearchParams,
		]
	);

	const mintTungsten = useCallback(
		async (accountAddress: string) => {
			try {
				if (drop2Contract) {
					const txn =
						await drop2Contract.contract.methods.tungstenMint();

					// calcualte the gas
					const gas = await txn.estimateGas({
						from: accountAddress,
					});

					// form the transaction parameters
					// @ts-ignore
					const transactionParameters = {
						to: drop2ContractAddress, // Required except during contract publications.
						from: accountAddress, // must match user's active address.
						gas: Web3.utils.numberToHex(gas),
						data: txn.encodeABI(),
					};

					// request the transaction
					// const txHash = await ethereum.request({
					// 	method: 'eth_sendTransaction',
					// 	params: [transactionParameters],
					// });

					// set the hash
					// if (!dontModifyParams) {
					// 	setSearchParams([
					// 		...getSearchParams(),
					// 		['txn', txHash],
					// 	]);
					// }
					// setTransactionHash(txHash);

					// fire GA event
					// logPurchaseEvent({
					// 	colorway: colorway,
					// 	method: 'tungsten mint',
					// 	transaction_hash: txHash,
					// 	gas: gas,
					// 	price: price,
					// 	currency: 'ETH',
					// 	address: accountAddress,
					// });
				}
			} catch (e: any) {
				if (e?.toString().includes('allowable mint limit')) {
					setError('Mint limit reached');
				} else if (
					e?.toString().includes('insufficient funds for gas')
				) {
					setError('Insufficient funds');
				} else {
					setError('Failed to mint');
				}
				logErrorEvent({
					action: 'tungsten mint',
					message: e.toString(),
				});
			}
			setModalOpen(false);
		},
		[
			colorway,
			dontModifyParams,
			drop2Contract,
			ethereum,
			getSearchParams,
			price,
			setSearchParams,
		]
	);

	const getMintButton = useCallback(() => {
		return (
			<div
				className={`drop2-mint-button-component ${
					fullWidth ? 'drop2-mint-button-full' : ''
				}`}
			>
				{transactionHash.length > 0 ? (
					<div className="drop2-mint-transaction">
						<p>
							Your transaction is processing...
							<br />
							This could take up to 30 minutes
						</p>
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://etherscan.io/tx/${transactionHash}`}
						>
							<p>View on Etherscan</p>
						</a>
					</div>
				) : (
					<>
						{!errorBelow && error.length > 0 && (
							<p className="drop2-mint-error">{error}</p>
						)}
						<Button
							className={`drop2-mint-button ${className}`}
							onClick={async () => {
								window.open(
									'https://opensea.io/collection/endstate-drop-2',
									'_blank',
									'noopener,noreferrer'
								);
							}}
						>
							{loading ? 'LOADING...' : `VIEW ON OPENSEA`}
						</Button>
						{errorBelow && error.length > 0 && (
							<p className="drop2-mint-error">{error}</p>
						)}
					</>
				)}
			</div>
		);
	}, [fullWidth, transactionHash, errorBelow, error, className, loading]);

	return (
		<>
			<Modal
				className="drop2-mint-modal"
				show={modalOpen}
				onExited={() => {
					setModalOpen(false);
					setShowExtraMintOptions(false);
				}}
				centered
			>
				<div className="close">
					<FaRegTimesCircle
						className="close-button"
						size={32}
						onClick={() => {
							setModalOpen(false);
						}}
					/>
				</div>
				<div className="title">
					{specialMintOpen
						? 'Drop 0 holders'
						: 'Do you want to mint using your Ethereum wallet or your credit card?'}
				</div>
				<div className="subtitle mt-4">
					{specialMintOpen
						? 'Mint for gas fees only using your Drop 0 NFT'
						: 'Minting with a credit card will create a new wallet for you that holds the NFT.'}
				</div>
				{loading && <div>Loading...</div>}
				{specialMintOpen ? (
					<div>
						<div className="d-flex justify-content-between mt-4">
							<Button
								className="drop2-mint-extra-button"
								disabled={!hasDropZero}
								onClick={() => {
									if (connectedWallet) {
										logEvent('mint_button_pressed', {
											button: 'drop0',
										});
										mintDrop0(connectedWallet);
									}
								}}
							>
								DROP ZERO MINT
							</Button>
							<Button
								className="drop2-mint-extra-button"
								disabled={!hasTungsten}
								onClick={() => {
									if (connectedWallet) {
										logEvent('mint_button_pressed', {
											button: 'tungsten',
										});
										mintTungsten(connectedWallet);
									}
								}}
							>
								TUNGSTEN ONLY MINT
							</Button>
						</div>
						{hasTungsten && (
							<div className="d-flex justify-content-between mt-2">
								<div></div>
								<p className="">
									All colorways will be minted at once
								</p>
							</div>
						)}
					</div>
				) : (
					<>
						{showExtraMintOptions ? (
							<div className="d-flex justify-content-between mt-4">
								{hasVoucher && (
									<Button
										className="drop2-mint-extra-button"
										onClick={() => {
											if (connectedWallet) {
												logEvent(
													'mint_button_pressed',
													{
														button: 'voucher',
													}
												);
												mintMftVoucher(connectedWallet);
											}
										}}
									>
										REDEEM VOUCHER
									</Button>
								)}
								<Button
									className="drop2-mint-extra-button"
									onClick={async () => {
										if (connectedWallet) {
											logEvent('mint_button_pressed', {
												button: 'wallet',
											});
											mintNftRaw(connectedWallet);
										}
									}}
								>
									{`MINT ${price} ETH`}
								</Button>
							</div>
						) : (
							<div className="d-flex justify-content-between mt-4">
								<Button
									className="wallet-btn"
									onClick={() => {
										if (
											hasVoucher ||
											(specialMintOpen &&
												(hasDropZero || hasTungsten))
										) {
											setShowExtraMintOptions(true);
										} else {
											if (connectedWallet) {
												logEvent(
													'mint_button_pressed',
													{
														button: 'wallet',
													}
												);
												mintNftRaw(connectedWallet);
											}
										}
									}}
								>
									MINT WITH WALLET
								</Button>
								<div className="wallet-btn">
									<PaperCheckout
										checkoutId={paperId}
										display={PaperCheckoutDisplay.MODAL}
										onOpenCheckout={() => {
											logEvent('mint_button_pressed', {
												button: 'paper',
											});
										}}
										onTransferSuccess={async (result) => {
											console.log(
												'transferSuccessful: ' +
													result.id
											);
											const transactionResult: any =
												await rawGet(
													'https://paper.xyz/api/v1/transaction-status/' +
														result.id
												);

											const txHash =
												transactionResult.result
													.claimedTokens.tokens[0]
													.transferHash;

											const web3Result =
												await web3.eth.getTransaction(
													txHash
												);

											const accountAddress =
												'0x' +
												web3Result.input.substring(
													34,
													74
												);

											logPurchaseEvent({
												colorway: colorway,
												method: 'paper',
												transaction_hash: txHash,
												price: price,
												currency: 'ETH',
												address: accountAddress,
											});

											const balance =
												await drop2Contract.balanceOf(
													accountAddress
												);

											const nftId =
												await drop2Contract.tokenOfOwnerByIndex(
													accountAddress,
													balance - 1
												);

											if (!dontModifyParams) {
												setSearchParams([
													...getSearchParams(),
													['id', nftId],
												]);
											}
											setNftId(nftId.toString());

											setSuccessModalAlreadyShown(false);

											document
												?.querySelector(
													'.paper-overlay'
												)
												?.remove();
											setModalOpen(false);
										}}
									>
										<Button
											variant="outline-secondary"
											className="paper-btn"
										>
											MINT WITH CREDIT CARD
										</Button>
									</PaperCheckout>
								</div>
							</div>
						)}
					</>
				)}
			</Modal>
			{nftMetadata && (
				<Drop2NFTModal
					show={showSuccessModal}
					onHide={() => setShowSuccessModal(false)}
					nftId={nftId}
					nftAnimationUri={nftMetadata.nftAnimationUri}
					attributes={nftMetadata.attributes}
				/>
			)}
			{getMintButton()}
		</>
	);
};

export default Drop2MintButton;
