import './AttributesSection.scss';

import React from 'react';

const AttributesSection: React.FC<any> = ({
	attributes,
}: {
	attributes: any[];
}) => {
	return (
		<div className="attributes">
			<div className="attributes-header-container">
				<div className="attributes-header">Attributes</div>
			</div>
			<div className="attributes-line-container">
				{attributes.map((attr, i) => (
					<div className="attributes-line" key={i}>
						<p>{attr.key}</p>
						<p>{attr.value}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default AttributesSection;
