// truffle contract

// old: 0x9F975D6FE945866454F0428Efb2eCDF4c67451d2
// old 2: 0x38AEcd64cafA8396cc840987F927e848E0aF534c
// Real: 0xd4EA80FfEE7d0E2A3132173C56baf604D20d40E5
// Local:0x5D85D898694ccc9c95Ec5f1D47edB9bba5334f0B
const blitkickContractAddresses: { [key: string]: any } = {
	development: '0x5D85D898694ccc9c95Ec5f1D47edB9bba5334f0B',
	staging: '',
	production: '0xd4EA80FfEE7d0E2A3132173C56baf604D20d40E5',
};
export const blitkickContractAddress =
	blitkickContractAddresses[process.env.REACT_APP_NODE_ENV || 'development'];

// Mainnet: 0x4ADb13C88AdBEC6d58Ab91A05ACb9623CEAa7eC5
// Rinkbey: 0xf3080BD3468b549c3C8295c494814DAa76552149
// Local: 0x6F257c91b4d3B61f2522e86Eec278f2Fc4d4Ef7e
const drop2VoucherContractAddresses: { [key: string]: any } = {
	development: '0x6F257c91b4d3B61f2522e86Eec278f2Fc4d4Ef7e',
	staging: '0xf3080BD3468b549c3C8295c494814DAa76552149',
	production: '0x4ADb13C88AdBEC6d58Ab91A05ACb9623CEAa7eC5',
};
export const drop2VoucherContractAddress =
	drop2VoucherContractAddresses[
		process.env.REACT_APP_NODE_ENV || 'development'
	];

// Mainnet: 0x140197fbb6119f17311f414c367d238d181d085d
// Rinkbey: 0x6A67c6C07190DeF80A1c9544f4aE96b01b0251De
const drop2ContractAddresses: { [key: string]: any } = {
	development: '',
	staging: '0x6A67c6C07190DeF80A1c9544f4aE96b01b0251De',
	production: '0x140197fbb6119f17311f414c367d238d181d085d',
};
export const drop2ContractAddress =
	drop2ContractAddresses[process.env.REACT_APP_NODE_ENV || 'development'];

// Mainnet: 0x2f70A2d41DD7641D18F9a56596445ddC86b63A5C
// Rinkbey: 0x74Aa62599651681FF2E768EB18253CB22baF5c55
const drop2AllowlistContractAddresses: { [key: string]: any } = {
	development: '',
	staging: '0x74Aa62599651681FF2E768EB18253CB22baF5c55',
	production: '0x2f70A2d41DD7641D18F9a56596445ddC86b63A5C',
};
export const drop2AllowlistContractAddress =
	drop2AllowlistContractAddresses[
		process.env.REACT_APP_NODE_ENV || 'development'
	];

// Mainnet: 0xBb94Cf2368773C1D78D9696aaD7af3ba16364F69
// Rinkbey: 0x54E16a90Fc5C3666B0C5867468607192af4b5C39
const dropZeroAllowlistContractAddresses: { [key: string]: any } = {
	development: '',
	staging: '0x54E16a90Fc5C3666B0C5867468607192af4b5C39',
	production: '0xBb94Cf2368773C1D78D9696aaD7af3ba16364F69',
};
export const dropZeroAllowListContractAddress =
	dropZeroAllowlistContractAddresses[
		process.env.REACT_APP_NODE_ENV || 'development'
	];

// Mainnet: 0xcaf4CBAfDCfb60e903B7E9667980a3A34EdC51a4
// Rinkbey: 0x470DA42d6F6a59e1Cb052E9eA3EdE0d5B190f211
const tungstenAllowlistContractAddresses: { [key: string]: any } = {
	development: '',
	staging: '0x470DA42d6F6a59e1Cb052E9eA3EdE0d5B190f211',
	production: '0xcaf4CBAfDCfb60e903B7E9667980a3A34EdC51a4',
};
export const tungstenAllowListContractAddress =
	tungstenAllowlistContractAddresses[
		process.env.REACT_APP_NODE_ENV || 'development'
	];

// Mainnet:
// Rinkbey:
const crowtherContractAddresses: { [key: string]: any } = {
	development: '',
	staging: '',
	production: '',
};
export const crowtherContractAddress =
	crowtherContractAddresses[process.env.REACT_APP_NODE_ENV || 'development'];

// Mainnet: 0x85F2B6c2aD91120B0D7bA901EcFe477Bc2216665
// Goerli:
const devontaContractAddresses: { [key: string]: any } = {
	development: '',
	staging: '0xD555dBBEa4cd9703C150883405fE6aE1cdBA145B',
	production: '0x85F2B6c2aD91120B0D7bA901EcFe477Bc2216665',
};
export const devontaContractAddress =
	devontaContractAddresses[process.env.REACT_APP_NODE_ENV || 'development'];
