import collateralRarity6 from "../assets/images/drop2/collateral/rarity6.mp4";
import redactedRarity6 from "../assets/images/drop2/redacted/rarity6.mp4";
import watergateRarity6 from "../assets/images/drop2/watergate/rarity6.mp4";
import emissaryRarity6 from "../assets/images/drop2/emissary/rarity6.mp4";

const paperIds: { [key: string]: any } = {
  redacted: {
    development: "",
    staging: "3cbcf92f-0a68-4708-8c03-b3ed56c090ad",
    production: "922b298b-7154-449c-b68e-0fe494aa4cbf",
  },
  watergate: {
    development: "",
    staging: "27fb0409-e103-4182-8a35-a93b6a40b857",
    production: "25811b5c-88af-4011-be49-87c63f632302",
  },
  collateral: {
    development: "",
    staging: "310c36d2-cd5c-411b-acab-151811e9d4e5",
    production: "8e1c283a-fd2a-4d8a-b6de-ec4dac78ac46",
  },
  emissary: {
    development: "",
    staging: "987a8fe5-5695-40bf-bd30-9f2729ab4b64",
    production: "7f571819-641c-48d5-99af-a6209c670f8a",
  },
};

const nftContent: {
  [key: string]: {
    id: number;
    nftArtUrl: string;
    paperId: string;
    sketchfabUrl: string;
  };
} = {
  redacted: {
    id: 0,
    nftArtUrl: redactedRarity6,
    paperId:
      paperIds["redacted"][process.env.REACT_APP_NODE_ENV || "development"],
    sketchfabUrl:
      "https://sketchfab.com/models/f3d279ef805e4489850e20022a1506d3/embed",
  },
  watergate: {
    id: 1,
    nftArtUrl: watergateRarity6,
    paperId:
      paperIds["watergate"][process.env.REACT_APP_NODE_ENV || "development"],
    sketchfabUrl:
      "https://sketchfab.com/models/de22a28c936f4a59a02a63b519ce4b0f/embed",
  },
  collateral: {
    id: 2,
    nftArtUrl: collateralRarity6,
    paperId:
      paperIds["collateral"][process.env.REACT_APP_NODE_ENV || "development"],
    sketchfabUrl:
      "https://sketchfab.com/models/c8c0d5f84e2c478cb0d8d597b2f61e40/embed",
  },
  emissary: {
    id: 3,
    nftArtUrl: emissaryRarity6,
    paperId:
      paperIds["emissary"][process.env.REACT_APP_NODE_ENV || "development"],
    sketchfabUrl:
      "https://sketchfab.com/models/1b6d7c143ea042f697f6c15172822af6/embed",
  },
};

const description =
  "Each NFT is redeemable for a premium pair of sneakers and the two are definitively linked via NFC chip. Both the NFTs and the physical sneakers will have variable traits across several tiers of rarity, which will be revealed upon minting. The metaverse environment in which your digital sneakers exist and the pedestal on which they rest vary from token to token. The style of laces and accessories reflected in your NFT correspond to the exact laces and accessories that will ship with your physical sneakers.";

const genericAttributes = [
  { key: "Release", value: "Drop 2" },
  { key: "Silhouette", value: "Statecraft" },
  { key: "Design Director", value: "Stephanie Howard" },
  { key: "Lead Designer", value: "David Filar" },
  { key: "Collaborator", value: "None" },
  { key: "Primary Color", value: undefined },
  { key: "Secondary Color:", value: undefined },
  { key: "Tertiary Color:", value: undefined },
  { key: "Made In", value: "USA with imported materials" },
  { key: "Lace Color", value: undefined },
  { key: "Embellishment", value: "[REDACTED]" },
  { key: "Pillar", value: "[REDACTED]" },
  {
    key: "Environment Design",
    value: "[REDACTED]",
  },
  { key: "Motif", value: "Asymmetrical" },
  { key: "Lace Location", value: "Tranverse Tongue" },
  { key: "Design Pattern", value: "Classic Paneling" },
  { key: "Design Style", value: "Cut-and-sew" },
  { key: "Midsole Color", value: undefined },
  { key: "Outsole Color", value: undefined },
  { key: "Heel Pattern", value: "Endstate Trident" },
  { key: "Logo Color", value: undefined },
  { key: "Medial Logo Color", value: undefined },
  { key: "Trident Color", value: undefined },
  { key: "Upper", value: "Premium Leather" },
  { key: "Sole", value: "Polyurethane" },
];

const attributesByColorway: { [key: string]: any[] } = {
  redacted: [
    { key: "Primary Color", value: "White Out" },
    { key: "Secondary Color:", value: "Ink Black" },
    { key: "Tertiary Color:", value: "White Out" },
    { key: "Lace Color", value: "White Out" },
    { key: "Midsole Color", value: "Ink Black" },
    { key: "Outsole Color", value: "Ink Black" },
    { key: "Logo Color", value: "Black" },
    { key: "Medial Logo Color", value: "Cloud Grey" },
    { key: "Trident Color", value: "Cloud Grey" },
  ],
  watergate: [
    { key: "Primary Color", value: "Ocean Blue" },
    { key: "Secondary Color:", value: "Coral Blue" },
    { key: "Tertiary Color:", value: "Pilot" },
    { key: "Lace Color", value: "Coral Blue" },
    { key: "Midsole Color", value: "Ivory" },
    { key: "Outsole Color", value: "Ink Black" },
    { key: "Logo Color", value: "Grey" },
    { key: "Medial Logo Color", value: "Cloud Grey" },
    { key: "Trident Color", value: "Grey" },
  ],
  collateral: [
    { key: "Primary Color", value: "Grey Zone" },
    { key: "Secondary Color:", value: "Deep Red" },
    { key: "Tertiary Color:", value: "Papyrus" },
    { key: "Lace Color", value: "Papyrus" },
    { key: "Midsole Color", value: "Ivory" },
    { key: "Outsole Color", value: "Ink Black" },
    { key: "Logo Color", value: "Black" },
    { key: "Medial Logo Color", value: "Ink Black" },
    { key: "Trident Color", value: "Deep Red" },
  ],
  emissary: [
    { key: "Primary Color", value: "Marine" },
    { key: "Secondary Color:", value: "Imperial" },
    { key: "Tertiary Color:", value: "White Sand" },
    { key: "Lace Color", value: "White Sand" },
    { key: "Midsole Color", value: "Ivory" },
    { key: "Outsole Color", value: "Ink Black" },
    { key: "Logo Color", value: "Black" },
    { key: "Medial Logo Color", value: "Ink Black" },
    { key: "Trident Color", value: "Imperial" },
  ],
};

const perks: { [key: string]: string[] } = {
  physical: [
    "• One pair of Drop 2: Statecraft sneakers",
    "• Italian leather upper",
    "• Lace types and accessories dependent on your NFT’s rarity",
    "• Military-grade polyurethane midsole and solid rubber outsole",
    "• Made in the USA with imported materials",
  ],
  digital: [
    "• 3D rendering of your Drop 2 sneakers in the Endstate metaverse environment",
    "• One of six tiers of rarities",
    "• One-to-one accessory matching with your Statecraft sneakers",
    "• Future metaverse integrations",
  ],
  experiential: [
    "• Exclusive access to token-gated events hosted by Endstate",
    "• Sneak peeks and input on future designs and collaborations",
    "• Entry into The State Department channel within the Endstate Discord",
    "• Authentication via NFC chip embedded in sneaker",
  ],
};

const getDrop2PageContent = (colorway: string) => {
  const attributes: any[] = structuredClone(genericAttributes);

  attributes.forEach((attr) => {
    if (attr.value === undefined) {
      attr.value = attributesByColorway[colorway].find(
        (abc) => abc.key === attr.key
      )?.value;
    }
  });

  return {
    attributes,
    description,
    perks,
    ...nftContent[colorway],
  };
};

export default getDrop2PageContent;
