import { useEffect } from 'react';

const Redirect = () => {
	useEffect(() => {
		window.location.href =
			'https://shop.endstate.io/products/red-bandanna-sneaker';
	}, []);

	return <div></div>;
};

export default Redirect;
