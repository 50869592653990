import kellygreenPose from '../assets/images/devonta/kellygreen.jpg';
import blackgoldPose from '../assets/images/devonta/blackgold.jpg';
import bluepinkPose from '../assets/images/devonta/bluepink.jpg';

import blackgold3q from '../assets/images/devonta/black/3q_b.png';
import blackgoldBack from '../assets/images/devonta/black/back_b.jpg';
import blackgoldFront from '../assets/images/devonta/black/front_b.jpg';
import blackgoldLateral from '../assets/images/devonta/black/lateral_b.jpg';
import blackgoldMedial from '../assets/images/devonta/black/medial_b.jpg';
import blackgoldTop from '../assets/images/devonta/black/top_b.jpg';

import kellygreen3q from '../assets/images/devonta/green/3q_b.png';
import kellygreenBack from '../assets/images/devonta/green/back_b.jpg';
import kellygreenFront from '../assets/images/devonta/green/front_b.jpg';
import kellygreenLateral from '../assets/images/devonta/green/lateral_b.jpg';
import kellygreenMedial from '../assets/images/devonta/green/medial_b.jpg';
import kellygreenTop from '../assets/images/devonta/green/top_b.jpg';

import bluepink3q from '../assets/images/devonta/blue/3q_b.png';
import bluepinkBack from '../assets/images/devonta/blue/back_b.jpg';
import bluepinkFront from '../assets/images/devonta/blue/front_b.jpg';
import bluepinkLateral from '../assets/images/devonta/blue/lateral_b.jpg';
import bluepinkMedial from '../assets/images/devonta/blue/medial_b.jpg';
import bluepinkTop from '../assets/images/devonta/blue/top_b.jpg';

const colorwayOverviewContent: { [key: string]: any } = {
	kellygreen: {
		id: 0,
		anchor: 'kellygreen',
		title: 'KELLY GREEN',
		paperTitle: 'Kelly Green',
		maxSupply: 125,
		images: [
			{
				src: kellygreenPose,
				alt: 'Hero pose of the Kelly Green shoes',
			},
			{
				src: kellygreenLateral,
				alt: 'Lateral pose of the Kelly Green shoes',
			},
			{
				src: kellygreenFront,
				alt: 'Front pose of the Kelly Green shoes',
			},
			{
				src: kellygreenMedial,
				alt: 'Medial pose of the Kelly Green shoes',
			},
			{
				src: kellygreenBack,
				alt: 'Back pose of the Kelly Green shoes',
			},
			{
				src: kellygreenTop,
				alt: 'Top pose of the Kelly Green shoes',
			},
		],
		threeQ: {
			src: kellygreen3q,
			alt: '3Q pose of the Kelly Green shoes',
		},
		paperId: '73c3a347-38d2-41ed-826e-0f22272d3214',
		gifUrl: 'https://arweave.net/qHciQ24XM5j7IpBWUY9te91E0-gkU96ayxT3nYs_H-g',
	},
	blackgold: {
		id: 2,
		anchor: 'blackgold',
		title: 'BLACK/GOLD',
		paperTitle: 'Black/Gold',
		maxSupply: 25,
		images: [
			{
				src: blackgoldPose,
				alt: 'Hero pose of the Black/Gold shoes',
			},
			{
				src: blackgoldLateral,
				alt: 'Lateral pose of the Black/Gold shoes',
			},
			{
				src: blackgoldFront,
				alt: 'Front pose of the Black/Gold shoes',
			},
			{
				src: blackgoldMedial,
				alt: 'Medial pose of the Black/Gold shoes',
			},
			{
				src: blackgoldBack,
				alt: 'Back pose of the Black/Gold shoes',
			},
			{
				src: blackgoldTop,
				alt: 'Top pose of the Black/Gold shoes',
			},
		],
		threeQ: {
			src: blackgold3q,
			alt: '3Q pose of the Black/Gold shoes',
		},
		paperId: '73c3a347-38d2-41ed-826e-0f22272d3214',
		gifUrl: 'https://arweave.net/OVFwfZNrjIg0P3vropAgRL9TZ9oJLgyIPGbcP29Bp14',
	},
	bluepink: {
		id: 1,
		anchor: 'bluepink',
		title: 'BLUE/PINK',
		paperTitle: 'Blue/Pink',
		maxSupply: 25,
		images: [
			{
				src: bluepinkPose,
				alt: 'Hero pose of the Blue/Pink shoes',
			},
			{
				src: bluepinkLateral,
				alt: 'Lateral pose of the Blue/Pink shoes',
			},
			{
				src: bluepinkFront,
				alt: 'Front pose of the Blue/Pink shoes',
			},
			{
				src: bluepinkMedial,
				alt: 'Medial pose of the Blue/Pink shoes',
			},
			{
				src: bluepinkBack,
				alt: 'Back pose of the Blue/Pink shoes',
			},
			{
				src: bluepinkTop,
				alt: 'Top pose of the Blue/Pink shoes',
			},
		],
		threeQ: {
			src: bluepink3q,
			alt: '3Q pose of the Blue/Pink shoes',
		},
		paperId: '73c3a347-38d2-41ed-826e-0f22272d3214',
		gifUrl: 'https://arweave.net/NlBMNPgqBo8pH5qcI6FYMOl9tK7lLpiwkQ5zzaUkRzg',
	},
};

export default colorwayOverviewContent;
