import './WalletModal.scss';

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaRegTimesCircle } from 'react-icons/fa';
import useShopify from '../../hooks/useShopify';

const NoWalletModal: React.FC<{ show: boolean; onHide: () => any }> = (
	props
) => {
	const { show, onHide } = props;

	const { proceedToPurchase, getCheckout } = useShopify();

	return (
		<Modal show={show} onHide={onHide} centered dialogClassName="">
			<div className="d-flex flex-column p-4">
				<div className="modal-close" onClick={onHide}>
					<FaRegTimesCircle size={32} />
				</div>
				<p>
					After checkout, you will recieve an email with instructions
					on how to claim your NFTs.
				</p>
				<div className="d-flex flex-row justify-content-center">
					<div className="mx-4">
						<Button
							onClick={async () =>
								proceedToPurchase(await getCheckout())
							}
						>
							Proceed to checkout
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default NoWalletModal;
