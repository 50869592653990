import './Cart.scss';
import React, { useState } from 'react';
import useShopify from '../../hooks/useShopify';

import placeholderImage from '../../assets/images/placeholder-image.jpeg';
import { Form } from 'react-bootstrap';

const formArray = Array.from({ length: 10 }, (_, i) => i + 1);

const CartLineItem: React.FC<any> = ({ lineItem }: { lineItem: any }) => {
	const { removeProductsFromCart, changeProductQuantity } = useShopify();

	const [loading, setLoading] = useState(false);

	const size = lineItem.customAttributes?.find((attrs: any) => {
		return attrs.key === 'size';
	});

	return (
		<div className="cart-line-item">
			<img
				className="cart-line-item-icon"
				src={lineItem.variant?.image?.src || placeholderImage}
				alt={`${lineItem.title} icon`}
			/>
			<div className="cart-line-item-details">
				<h2>{lineItem.title}</h2>

				<p className="cart-size-text">{size?.value}</p>

				<button
					className="link-button"
					onClick={() => {
						if (loading) {
							return;
						}
						setLoading(true);
						removeProductsFromCart([lineItem.id]).then(() =>
							setLoading(false)
						);
					}}
				>
					Remove
				</button>
			</div>
			<div className="d-flex flex-column align-items-end justify-content-between">
				<Form.Select
					value={lineItem.quantity}
					onChange={(e: any) => {
						if (loading) {
							return;
						}
						setLoading(true);
						const newQuantity = parseInt(e.target.value);

						changeProductQuantity(lineItem.id, newQuantity).then(
							() => setLoading(false)
						);
					}}
				>
					{formArray.map((i) => (
						<option key={i} value={i.toString()}>
							{i}
						</option>
					))}
				</Form.Select>
				<p className="cart-line-item-price">
					{loading ? (
						'Updating'
					) : (
						<>{`$${parseInt(
							lineItem.variant.price.amount
						).toLocaleString(undefined, {
							minimumFractionDigits: 2,
						})}${
							lineItem.quantity > 1
								? ` x ${lineItem.quantity}`
								: ''
						}`}</>
					)}
				</p>
			</div>
		</div>
	);
};

export default CartLineItem;
