import './DevontaMintButton.scss';

import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { logEvent } from '../../../services/AnalyticsService';
import DevontaMintModal from './DevontaMintModal';
// import { getDevontaContract } from '../../../blockchain/contracts';

const DevontaMintButton: React.FC<any> = ({
	colorwayContent,
	className,
	fullWidth = false,
	errorBelow = false,
	onClick = undefined,
}: {
	colorwayContent: any;
	className: string;
	buttonText: string | undefined;
	fullWidth: boolean;
	errorBelow: boolean;
	dontModifyParams: boolean;
	onClick: undefined | (() => {});
}) => {
	const [price] = useState<string>('$250');

	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [isSoldOut, setIsSoldOut] = useState<boolean>(true);

	const queryParams = new URLSearchParams(window.location.search);
	let ignoreSupply = queryParams.get('o') === 'fdse37d';
	let mintOpen = true;

	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		(async () => {
			setLoading(true);
			// const devontaContract = await getDevontaContract();
			// const supply = await devontaContract.variationTotalSupply(
			// 	colorwayContent.id
			// );

			// setIsSoldOut(supply >= colorwayContent.maxSupply && !ignoreSupply);
			setLoading(false);
		})();
	}, [colorwayContent, ignoreSupply]);

	const getMintButton = useCallback(() => {
		return (
			<div
				className={`devonta-mint-button-component ${
					fullWidth ? 'devonta-mint-button-full' : ''
				}`}
			>
				{!errorBelow && error.length > 0 && (
					<p className="devonta-mint-error">{error}</p>
				)}
				<Button
					className={`devonta-mint-button ${className}`}
					disabled={isSoldOut || loading}
					onClick={() => {
						if (!mintOpen) {
							return;
						}
						if (onClick) {
							try {
								logEvent('buy_button_pressed', {
									colorway: colorwayContent.anchor,
								});
								onClick();
							} catch (error: any) {
								setError('Something went wrong with minting');
							}
						} else {
							setShowModal(true);
						}
					}}
				>
					{!mintOpen
						? 'RELEASING 9/24'
						: loading
						? `LOADING...`
						: isSoldOut
						? `SOLD OUT`
						: `BUY ${price}`}
				</Button>
				{errorBelow && error.length > 0 && (
					<p className="devonta-mint-error">{error}</p>
				)}
			</div>
		);
	}, [
		fullWidth,
		errorBelow,
		error,
		className,
		isSoldOut,
		loading,
		mintOpen,
		onClick,
		price,
		colorwayContent,
	]);

	return (
		<>
			<DevontaMintModal
				show={showModal}
				handleClose={() => setShowModal(false)}
				title={colorwayContent.title}
				colorwayContent={colorwayContent}
			/>
			{getMintButton()}
		</>
	);
};

export default DevontaMintButton;
