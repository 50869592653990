import './DevontaOverview.scss';

import React, { useRef } from 'react';
import { Button, Carousel } from 'react-bootstrap';

import howItWorks1 from '../../assets/images/devonta/howItWorks1.png';
import howItWorks2 from '../../assets/images/devonta/howItWorks2.png';
import howItWorks3 from '../../assets/images/devonta/howItWorks3.png';

import devontaVideo from '../../assets/images/devonta/devonta-video.mp4';
import devontaVerticalVideo from '../../assets/images/devonta/devonta-vertical-video.mp4';

import colorMorphVideo from '../../assets/images/devonta/three-colorways.mp4';
import DevontaMintButton from '../../components/mint/devonta/DevontaMintButton';
import { isMobile } from 'react-device-detect';
import colorwayOverviewContent from '../../drops/devontaPageContent';

const DevontaOverview: React.FC<any> = () => {
	const scrollToRef = useRef<HTMLDivElement | null>(null);
	const scrollToKellyGreenRef = useRef<HTMLDivElement | null>(null);
	const scrollToGoldBlackRef = useRef<HTMLDivElement | null>(null);
	const scrollToBluePinkRef = useRef<HTMLDivElement | null>(null);
	const scrollToOverviewRef = useRef<HTMLDivElement | null>(null);

	const getColorwayOverview = (colorway: string, index: number) => {
		const colorwayContent = colorwayOverviewContent[colorway];

		const getRef = (colorway: string) => {
			if (colorway === 'kellygreen') {
				return scrollToKellyGreenRef;
			} else if (colorway === 'blackgold') {
				return scrollToGoldBlackRef;
			} else if (colorway === 'bluepink') {
				return scrollToBluePinkRef;
			}
		};

		return (
			<div
				className={`devonta-overview ${
					index % 2 === 0
						? 'devonta-overview-rtl'
						: 'devonta-overview-ltr'
				}`}
				ref={getRef(colorway)}
				key={colorwayContent.id}
				id={colorwayContent.anchor}
			>
				<div className="devonta-overview-colorway">
					<div>
						<h1>{colorwayContent.title}</h1>
						<p>{colorwayContent.subtitle}</p>
						<div className="devonta-overview-mint-button-container">
							<span className="devonta-overview-mint-button-parent">
								<DevontaMintButton
									className="devonta-overview-mint-button"
									colorwayContent={colorwayContent}
									errorBelow
								/>
							</span>
						</div>
					</div>
				</div>
				<Carousel className="devonta-overview-image" interval={null}>
					{colorwayContent.images.map((image: any, i: number) => (
						<Carousel.Item key={i}>
							<img src={image.src} alt={image.alt} />
						</Carousel.Item>
					))}
				</Carousel>
			</div>
		);
	};

	return (
		<div className="devonta-container">
			<div className="devonta-background-video">
				<video
					src={isMobile ? devontaVerticalVideo : devontaVideo}
					autoPlay
					muted
					loop
					playsInline
				/>
			</div>
			<div className="devonta-top" ref={scrollToOverviewRef}>
				<h1 className="devonta-overview-header">
					<div>
						DEVONTA SMITH
						<br />X ENDSTATE
					</div>
					<DevontaMintButton
						className="devonta-overview-mint-button"
						colorwayContent={colorwayOverviewContent['kellygreen']}
						errorBelow
					/>
				</h1>

				<div
					className={`devonta-overview devonta-overview-ltr ${
						isMobile && 'flex-column'
					}`}
				>
					<div className="devonta-overview-statecraft">
						<h2>By DeVonta. For Fans.</h2>
						<h3>PREMIUM SNEAKERS</h3>
						<p>
							Designed with DeVonta to his exact specifications
							for style and comfort.
						</p>
						<h3>UNPARALLELED ACCESS</h3>
						<p>
							Tickets to an event hosted by DeVonta in Philly and
							an exclusive Eagles watch party. Go Birds.
						</p>
						<h3>NEXT LEVEL FAN EXPERIENCES</h3>
						<p>When DeVonta wins, you win.</p>
						<div className="devonta-overview-perks-table-container">
							<table className="devonta-overview-perks-table">
								<tbody>
									<tr>
										<th>DEVONTA</th>
										<th></th>
										<th>YOU</th>
									</tr>
									<tr>
										<td>41+ yard play</td>
										<td>=</td>
										<td>Cheesesteak</td>
									</tr>
									<tr>
										<td>1,000 receiving yards</td>
										<td>=</td>
										<td>Limited edition football card</td>
									</tr>
									<tr>
										<td>NFL MVP award</td>
										<td>=</td>
										<td>Luxury watch</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="d-flex flex-column devonta-overview-header-assets">
						<video
							className="devonta-overview-video"
							src={colorMorphVideo}
							autoPlay
							muted
							loop
							playsInline
						/>
					</div>
				</div>
				<div className="devonta-overview-singles">
					<img
						src={colorwayOverviewContent['blackgold'].threeQ.src}
						alt="black/gold shortcut"
						onClick={() =>
							scrollToGoldBlackRef?.current?.scrollIntoView()
						}
					/>
					<img
						src={colorwayOverviewContent['kellygreen'].threeQ.src}
						alt="kelly green shortcut"
						onClick={() =>
							scrollToKellyGreenRef?.current?.scrollIntoView()
						}
					/>
					<img
						src={colorwayOverviewContent['bluepink'].threeQ.src}
						alt="blue/pink shortcut"
						onClick={() =>
							scrollToBluePinkRef?.current?.scrollIntoView()
						}
					/>
				</div>
				<div className="devonta-overview-ticker" ref={scrollToRef}>
					<p>PREMIUM SNEAKERS</p>
					<p>•</p>
					<p>EXCLUSIVE EVENT ACCESS</p>
					<p>•</p>
					<p>GAMEDAY PERKS</p>
					<p>•</p>
					<p>BONUS PRODUCTS & MEMORABILIA</p>
				</div>
				{getColorwayOverview('kellygreen', 0)}
				{getColorwayOverview('blackgold', 1)}
				{getColorwayOverview('bluepink', 2)}
				<div className="devonta-overview-howitworks">
					<h1 className="devonta-overview-howitworks-header">
						HOW IT WORKS
					</h1>
					<div className="devonta-overview-howitworks-steps">
						<div className="devonta-overview-howitworks-step">
							<h1>1.</h1>
							<div className="devonta-overview-howitworks-step-content">
								<img
									className="devonta-overview-howitworks-icon icon-1"
									src={howItWorks1}
									alt="icon showing nfc chips in the shoes"
								/>
								<h2>PURCHASE SNEAKERS</h2>
								<p>
									• Your Honey Drip sneakers have an NFC chip
									embedded in the tongue
								</p>
								<p>
									• The NFC chip is connected to your NFT
									which authenticates your sneakers and grants
									you access to perks, events, and the chance
									to win memorabilia throughout the 2022
									season
								</p>
								<p>
									• You can purchase with a credit card or
									cryptocurrency (ETH)
								</p>
							</div>
						</div>
						<div className="devonta-overview-howitworks-step">
							<h1>2.</h1>
							<div className="devonta-overview-howitworks-step-content">
								<img
									className="devonta-overview-howitworks-icon icon-2"
									src={howItWorks2}
									alt="icon showing hand holding ticket"
								/>
								<h2>
									YOUR SNEAKERS ARE
									<br />
									YOUR TICKET TO
								</h2>
								<p>
									• An event hosted by DeVonta in Philadelphia
								</p>
								<p>
									• An exclusive watch party for an Eagles
									game
								</p>
								<p>• Access to other Endstate events</p>
							</div>
						</div>
						<div className="devonta-overview-howitworks-step">
							<h1>3.</h1>
							<div className="devonta-overview-howitworks-step-content">
								<img
									className="devonta-overview-howitworks-icon icon-3"
									src={howItWorks3}
									alt="icon showing circuit board"
								/>
								<h2>CONNECTED FAN EXPERIENCES</h2>
								<p>
									• Free Cheesesteak for Sneaker/NFT owners:
									when DeVonta scores his first TD or if
									DeVonta has a play of 41+ yards at any point
									during the season after the sneaker launches
								</p>
								<p>
									• If DeVonta has more than 1,000 yards
									receiving on the season, you get a limited
									edition DeVonta Smith football card
								</p>
								<p>
									• If DeVonta wins MVP you get a luxury watch
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DevontaOverview;
