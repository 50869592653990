import Client from 'shopify-buy';
import { get } from '../services/FetchService';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { useCallback, useEffect } from 'react';
import { useAccount } from 'wagmi';

const { persistAtom } = recoilPersist();

const shopifyCheckoutIdState = atom({
	key: `shopifyCheckoutIdState_${process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN}`,
	default: undefined,
	effects_UNSTABLE: [persistAtom],
});
const cartSizeState = atom({
	key: `cartSizeState_${process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN}`,
	default: 0,
});

const client = Client.buildClient({
	domain: process.env.REACT_APP_SHOPIFY_URL || '',
	storefrontAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN || '',
});

const useShopify = () => {
	const [checkoutId, setCheckoutId] = useRecoilState(shopifyCheckoutIdState);
	const [cartSize, setCartSize] = useRecoilState(cartSizeState);

	const { address } = useAccount();

	const getCheckoutId = useCallback(async () => {
		let id = checkoutId;
		if (!checkoutId) {
			const checkout = await client.checkout.create();
			id = checkout.id;
			setCheckoutId(id);
		}

		return id;
	}, [checkoutId, setCheckoutId]);

	const getCheckout = useCallback(async () => {
		const checkout = await client.checkout.fetch(await getCheckoutId());
		return checkout;
	}, [getCheckoutId]);

	useEffect(() => {
		getCheckout().then((checkout) => {
			if (checkout?.lineItems.length > 0) {
				setCartSize(
					checkout.lineItems
						.map((item) => item.quantity)
						.reduce((prev, next) => prev + next)
				);
			}
		});
	}, [getCheckout, setCartSize]);

	const updateCartSize = useCallback(async () => {
		const checkout = await getCheckout();

		if (checkout?.lineItems.length) {
			setCartSize(
				checkout.lineItems
					.map((item) => item.quantity)
					.reduce((prev, next) => prev + next)
			);
		} else {
			setCartSize(0);
		}
	}, [setCartSize, getCheckout]);

	const addProductToCart = useCallback(
		async (variantId: number, size?: string) => {
			const customAttributes = [];
			if (size) {
				customAttributes.push({ key: 'size', value: size });
			}

			const lineItemsToAdd = [
				{
					variantId: `gid://shopify/ProductVariant/${variantId}`,
					quantity: 1,
					customAttributes,
				},
			];

			const checkout = await getCheckout();

			if (checkout) {
				await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
			}
			updateCartSize();
		},
		[getCheckout, updateCartSize]
	);

	const removeProductsFromCart = useCallback(
		async (lineItemIds: string[]) => {
			const checkout = await getCheckout();
			if (checkout) {
				await client.checkout.removeLineItems(checkout.id, lineItemIds);
			}
			updateCartSize();
		},
		[getCheckout, updateCartSize]
	);

	const changeProductQuantity = useCallback(
		async (lineItemId: string, newQuantity: number) => {
			const checkout = await getCheckout();
			if (checkout) {
				await client.checkout.updateLineItems(checkout.id, [
					{ id: lineItemId, quantity: newQuantity },
				]);
			}
			updateCartSize();
		},
		[getCheckout, updateCartSize]
	);

	const prepareCheckout = async (
		variantId: string
	): Promise<{ url: string; id: number | string }> => {
		// Create an empty checkout
		const checkout = await client.checkout.create();

		const checkoutId = checkout.id; // ID of an existing checkout
		setCheckoutId(checkoutId);
		const lineItemsToAdd = [
			{
				variantId: `gid://shopify/ProductVariant/${variantId}`,
				quantity: 1,
			},
		];

		// Add an item to the checkout
		await client.checkout.addLineItems(checkoutId, lineItemsToAdd);

		return { url: checkout.webUrl, id: checkout.id };
	};

	const addVariantToCheckout = async (
		checkoutId: string,
		variantId: number
	) => {
		const lineItemsToAdd = [
			{
				variantId: `gid://shopify/ProductVariant/${variantId}`,
				quantity: 1,
			},
		];
		await client.checkout.addLineItems(checkoutId, lineItemsToAdd);
	};

	const proceedToPurchase = useCallback(
		async (checkout: ShopifyBuy.Cart) => {
			let finalCheckout = checkout;
			// create new checkout with the address data
			if (address) {
				finalCheckout = await client.checkout.create();

				await client.checkout.addLineItems(
					finalCheckout.id,
					checkout.lineItems.map((lineItem) => {
						return {
							// @ts-ignore
							variantId: lineItem.variant.id,
							quantity: lineItem.quantity,
							customAttributes: [
								// @ts-ignore
								...lineItem.customAttributes.map(
									(attr: any) => {
										return {
											key: attr.key,
											value: attr.value,
										};
									}
								),
								{ key: 'wallet', value: address },
							],
						};
					})
				);
			}
			setCheckoutId(finalCheckout.id);

			// open the checkout url (for crowther, this is .url)
			// @ts-ignore
			window.location.href = finalCheckout.webUrl || finalCheckout.url;
		},
		[address, setCheckoutId]
	);

	const clearCart = useCallback(() => {
		setCheckoutId(undefined);
		setCartSize(0);
	}, [setCheckoutId, setCartSize]);

	const getProductVariants = async (productId: string) => {
		const results: any = await get(`/products?productId=${productId}`);

		return results.products[0].variants;
	};

	const getProductInfo = (variantId: string): Promise<Client.Product> => {
		return client.product.fetch(`gid://shopify/Product/${variantId}`);
	};

	return {
		client,
		prepareCheckout,
		proceedToPurchase,
		getProductVariants,
		addVariantToCheckout,
		getCheckoutId,
		getCheckout,
		addProductToCart,
		removeProductsFromCart,
		clearCart,
		cartSize,
		getProductInfo,
		changeProductQuantity,
	};
};

export default useShopify;
