import "./Drop2.scss";

import React from "react";

import { Dropdown, DropdownButton, Form, Tab, Tabs } from "react-bootstrap";

import Drop2MintDetails from "../../components/mint/Drop2MintDetails";
import { useSearchParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

const Drop2: React.FC<any> = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const colorway = searchParams.get("drop2_colorway") || "redacted";

  return (
    <div className="drop2" lang="en">
      <div className="drop2-title">DROP 2: STATECRAFT</div>
      <MobileView>
        <Form.Select
          className="drop2-colorways-dropdown"
          value={colorway}
          onChange={(e) =>
            setSearchParams([["drop2_colorway", e.target.value || ""]])
          }
        >
          <option value="redacted">REDACTED</option>
          <option value="watergate">WATERGATE</option>
          <option value="collateral">COLLATERAL</option>
          <option value="emissary">EMISSARY</option>
        </Form.Select>
      </MobileView>
      <Tabs
        activeKey={colorway}
        defaultActiveKey="redacted"
        id="colorways-tab"
        className="drop2-colorways-tabs"
        transition={false}
        unmountOnExit
        onSelect={(key) =>
          setSearchParams([["drop2_colorway", key?.toLowerCase() || ""]])
        }
      >
        <Tab
          eventKey="redacted"
          title="REDACTED"
          className="drop2-colorways-tab"
        >
          <div className="drop2-mint-details-container">
            <Drop2MintDetails colorway="redacted" />
          </div>
        </Tab>
        <Tab eventKey="watergate" title="WATERGATE">
          <div className="drop2-mint-details-container">
            <Drop2MintDetails colorway="watergate" />
          </div>
        </Tab>
        <Tab eventKey="collateral" title="COLLATERAL">
          <div className="drop2-mint-details-container">
            <Drop2MintDetails colorway="collateral" />
          </div>
        </Tab>
        <Tab eventKey="emissary" title="EMISSARY">
          <div className="drop2-mint-details-container">
            <Drop2MintDetails colorway="emissary" />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Drop2;
