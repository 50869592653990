import './DevontaMintModal.scss';

import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { get, post } from '../../../services/FetchService';
import { ShippingForm, ShippingInfo } from './ShippingForm';

import {
	devontaContractAddress,
	// getDevontaContract,
} from '../../../blockchain/contracts';
import colorwayOverviewContent from '../../../drops/devontaPageContent';
import { logErrorEvent, logEvent } from '../../../services/AnalyticsService';
import { isMobile } from 'react-device-detect';

const shoeImages: { [key: string]: any } = {
	kellygreen: colorwayOverviewContent['kellygreen'].images[0],
	'blue/pink': colorwayOverviewContent['bluepink'].images[0],
	'black/gold': colorwayOverviewContent['blackgold'].images[0],
};

const sizes = [
	{
		mens: '3.5',
		womens: '5',
		label: 'US M 3.5 | W 5',
	},
	{
		mens: '4',
		womens: '5.5',
		label: 'US M 4 | W 5.5',
	},
	{
		mens: '4.5',
		womens: '6',
		label: 'US M 4.5 | W 6',
	},
	{
		mens: '5',
		womens: '6.5',
		label: 'US M 5 | W 6.5',
	},
	{
		mens: '5.5',
		womens: '7',
		label: 'US M 5.5 | W 7',
	},
	{
		mens: '6',
		womens: '7.5',
		label: 'US M 6 | W 7.5',
	},
	{
		mens: '6.5',
		womens: '8',
		label: 'US M 6.5 | W 8',
	},
	{
		mens: '7',
		womens: '8.5',
		label: 'US M 7 | W 8.5',
	},
	{
		mens: '7.5',
		womens: '9',
		label: 'US M 7.5 | W 9',
	},
	{
		mens: '8',
		womens: '9.5',
		label: 'US M 8 | W 9.5',
	},
	{
		mens: '8.5',
		womens: '10',
		label: 'US M 8.5 | W 10',
	},
	{
		mens: '9',
		womens: '10.5',
		label: 'US M 9 | W 10.5',
	},
	{
		mens: '9.5',
		womens: '11',
		label: 'US M 9.5 | W 11',
	},
	{
		mens: '10',
		womens: '11.5',
		label: 'US M 10 | W 11.5',
	},
	{
		mens: '10.5',
		womens: '12',
		label: 'US M 10.5 | W 12',
	},
	{
		mens: '11',
		womens: '12.5',
		label: 'US M 11 | W 12.5',
	},
	{
		mens: '11.5',
		womens: '13',
		label: 'US M 11.5 | W 13',
	},
	{
		mens: '12',
		womens: '13.5',
		label: 'US M 12 | W 13.5',
	},
	{
		mens: '12.5',
		womens: '14',
		label: 'US M 12.5 | W 14',
	},
	{
		mens: '13',
		womens: '14.5',
		label: 'US M 13 | W 14.5',
	},
	{
		mens: '13.5',
		womens: '15',
		label: 'US M 13.5 | W 15',
	},
	{
		mens: '14',
		womens: '15.5',
		label: 'US M 14 | W 15.5',
	},
	{
		mens: '15',
		womens: '16.5',
		label: 'US M 15 | W 16.5',
	},
	{
		mens: '16',
		womens: '17.5',
		label: 'US M 16 | W 17.5',
	},
];

const defaultShippingInfo = {
	firstName: '',
	lastName: '',
	address1: '',
	address2: '',
	city: '',
	zip: '',
	country: 'US',
	province: '',
	email: '',
	phone: '',
};

const DevontaMintModal: React.FC<any> = ({
	show,
	handleClose,
	colorwayContent: defaultColorwayContent,
}) => {
	const [modalState, setModalState] = useState<
		'sizes' | 'shipping' | 'payment' | 'processing'
	>('sizes');

	const [genderSize, setGenderSize] = useState<'mens' | 'womens'>('mens');
	const [shippingInfo, setShippingInfo] =
		useState<ShippingInfo>(defaultShippingInfo);
	const [sizeError, setSizeError] = useState<string>('');
	const [selectedSize, setSelectedSize] = useState<string>('');
	const [checkoutLink, setCheckoutLink] = useState('');
	const [checkoutKey, setCheckoutKey] = useState('');
	const [loading, setLoading] = useState(false);
	const [colorwayContent, setColorwayContent] = useState(
		defaultColorwayContent
	);
	const [title, setTitle] = useState('');
	const [error, setError] = useState('');
	const queryParams = new URLSearchParams(window.location.search);
	const testNewCheckout = queryParams.get('dev') === 'checkout';

	useEffect(() => {
		setTitle(colorwayContent.title);
	}, [colorwayContent]);

	const closeModal = () => {
		handleClose();
		setSelectedSize('');
		setShippingInfo(defaultShippingInfo);
		setModalState('sizes');
	};

	const fetchAndOpenCheckoutUrl = useCallback(
		async (shippingInfo: ShippingInfo) => {
			console.log(shippingInfo);
			console.log(selectedSize);
			console.log(colorwayContent);
			logEvent('checkout_url_opened', {
				colorway: colorwayContent.anchor,
				size: selectedSize,
				gender_size: genderSize,
			});
			const result = await post(`/paper/linkIntent/devonta`, {
				body: JSON.stringify({
					shippingInfo: shippingInfo,
					shoeSize: selectedSize,
					variation: colorwayContent.id,
					colorway: colorwayContent.paperTitle,
					shoeName: 'DeVonta Smith x Endstate',
					imageUrl: colorwayContent.gifUrl,
				}),
			});
			if (isMobile) {
				console.log('Using checkout link fix');
				window?.location?.assign(result.checkoutLinkIntentUrl);
			} else {
				console.log('Using old checkout link');
				window.open(
					result.checkoutLinkIntentUrl,
					'_blank',
					'noopener,noreferrer'
				);
			}

			setCheckoutLink(result.checkoutLinkIntentUrl);
			setCheckoutKey(result.checkoutKey);
		},
		[selectedSize, colorwayContent, genderSize]
	);

	const SizeSelection = () => {
		return (
			<>
				<h4 className="mb-4 mt-4">SELECT SIZE</h4>
				<div>
					<span
						className={`gender-selector ${
							genderSize === 'mens' && 'gender-selected'
						}`}
						onClick={() => setGenderSize('mens')}
					>
						MEN'S
					</span>
					<span>|</span>
					<span
						className={`gender-selector ${
							genderSize === 'womens' && 'gender-selected'
						}`}
						onClick={() => setGenderSize('womens')}
					>
						WOMEN'S
					</span>
				</div>

				<div className="devonta-mint-modal-sizes">
					{sizes.map((size, i) => (
						<div
							className={`devonta-mint-modal-size-button ${
								size.label === selectedSize
									? 'devonta-mint-modal-size-selected'
									: 'devonta-mint-modal-size-unselected'
							}`}
							key={i}
							onClick={() => setSelectedSize(size.label)}
						>
							{genderSize === 'mens' ? size.mens : size.womens}
						</div>
					))}
				</div>
				{sizeError.length > 0 && (
					<div className="devonta-mint-modal-error-text">
						{sizeError}
					</div>
				)}
				<div className="devonta-mint-modal-actions">
					<Button
						onClick={() => {
							if (selectedSize.length === 0) {
								setSizeError('SELECT A SIZE');
								return;
							}
							setSizeError('');
							logEvent('shipping_details_clicked', {
								colorway: colorwayContent.anchor,
								size: selectedSize,
								gender_size: genderSize,
							});
							setModalState('shipping');
						}}
					>
						SHIPPING DETAILS
					</Button>
				</div>
			</>
		);
	};

	const Shipping = () => {
		return (
			<>
				<h4 className="mb-4 mt-4">SHIP TO</h4>
				<ShippingForm
					loading={loading}
					shippingInfo={shippingInfo || defaultShippingInfo}
					error={error}
					onSubmit={async (shippingInfo: ShippingInfo) => {
						setLoading(true);
						setError('');
						setShippingInfo(shippingInfo);
						try {
							await fetchAndOpenCheckoutUrl(shippingInfo);
							setModalState('processing');
						} catch (e: any) {
							console.error(e);
							setError(
								'Something went wrong. Please try again in a few minutes.'
							);
							logErrorEvent({ message: e.toString() });
						} finally {
							setLoading(false);
						}
					}}
				/>
			</>
		);
	};

	const Payment = () => {
		return (
			<>
				<Button
					onClick={() => {
						window.open(
							checkoutLink,
							'_blank',
							'noopener,noreferrer'
						);
					}}
					disabled={loading}
				>
					{loading ? 'LOADING' : 'CHECKOUT'}
				</Button>
			</>
		);
	};

	const Processing = ({ checkoutKey }: { checkoutKey: string }) => {
		const [paperState, setPaperState] = useState<
			'pending' | 'success' | 'error'
		>('pending');
		const [tokenId, setTokenId] = useState('');
		const [error, setError] = useState('');
		const [loading, setLoading] = useState(false);
		const [metadata, setMetadata] = useState<any>();

		useEffect(() => {
			if (paperState === 'pending') {
				const interval = setInterval(() => {
					get(
						`/paper/purchaseStatus?checkoutKey=${checkoutKey}`
					).then((result: any) => {
						if (result.state === 'success') {
							setPaperState('success');
							setTokenId(result.tokenId);
							logEvent('success', { token_id: tokenId });
							setTitle('Congratulations!');
						} else if (result.state === 'error') {
							setPaperState('error');
							setError(result.error);
							logErrorEvent({ message: result.error });
							setTitle('Something went wrong');
						}
					});
				}, 10000);
				return () => clearInterval(interval);
			}
		}, [checkoutKey, paperState, tokenId]);

		useEffect(() => {
			if (tokenId.length) {
				(async () => {
					setLoading(true);
					// const devontaContract = await getDevontaContract();
					// const tokenURI = await devontaContract.tokenURI(tokenId);
					// const result = await (await fetch(tokenURI)).json();

					// setMetadata(result);

					setLoading(false);
				})();
			}
		}, [tokenId]);

		return (
			<>
				{paperState === 'pending' && (
					<>
						<p className="mb-4">
							Please navigate to the checkout page to complete
							your purchase.
						</p>
						<Button
							onClick={async () => {
								await fetchAndOpenCheckoutUrl(shippingInfo);
							}}
							disabled={loading}
						>
							CONTINUE CHECKOUT
						</Button>
					</>
				)}
				{paperState === 'success' && (
					<>
						<p className="mb-3">
							Your NFT is in your wallet and your sneakers are in
							production. Stay tuned to Endstate socials for
							updates.
						</p>
						<h4 className="mb-1">{metadata?.name}</h4>
						{/* <a
							className="devonta-mint-modal-rarible"
							href={`https://rarible.com/token/${devontaContractAddress}:${tokenId}?tab=overview`}
						>
							View your NFT on Rarible
						</a> */}
						<a
							className="devonta-mint-modal-link"
							href={`https://opensea.io/assets/ethereum/${devontaContractAddress}/${tokenId}`}
						>
							View your NFT on OpenSea
						</a>
						<div className="devonta-mint-modal-video-container">
							{/* <video
								className="devonta-mint-modal-video"
								src={metadata?.animation_url}
								loop
								autoPlay
								muted
								playsInline
							/> */}
							<img
								alt={
									shoeImages[
										metadata?.attributes
											.find(
												(attr: {
													trait_type: string;
												}) =>
													attr.trait_type ===
													'Colorway'
											)
											.value.replaceAll(' ', '')
											.toLowerCase()
									]?.alt
								}
								src={
									shoeImages[
										metadata?.attributes
											.find(
												(attr: {
													trait_type: string;
												}) =>
													attr.trait_type ===
													'Colorway'
											)
											.value.replaceAll(' ', '')
											.toLowerCase()
									]?.src
								}
							/>
						</div>
					</>
				)}
				{paperState === 'error' && <></>}
			</>
		);
	};

	return (
		<Modal
			show={show}
			onHide={closeModal}
			centered
			dialogClassName="devonta-mint-modal"
		>
			<Modal.Body>
				<div className="devonta-mint-modal-body">
					{/* <div className="close">
						<FaRegTimesCircle
							className="close-button"
							size={26}
							onClick={() => {
								handleClose();
							}}
						/>
					</div> */}
					<div>
						{modalState === 'sizes' || modalState === 'shipping' ? (
							<select
								className="devonta-modal-select"
								value={colorwayContent.anchor}
								onChange={(e: any) => {
									logEvent('modal_colorway_selected', {
										colorway: e.target.value,
									});
									setColorwayContent(
										colorwayOverviewContent[e.target.value]
									);
								}}
							>
								<option
									key={'kelly green'}
									value={'kellygreen'}
								>
									KELLY GREEN
								</option>
								<option key={'blackgold'} value={'blackgold'}>
									BLACK / GOLD
								</option>
								<option key={'bluepink'} value={'bluepink'}>
									BLUE / PINK
								</option>
							</select>
						) : (
							<h1>{title}</h1>
						)}

						{modalState === 'shipping' && (
							<span
								className="modal-clickable"
								onClick={() => {
									setModalState('sizes');
								}}
							>
								{selectedSize}
							</span>
						)}
					</div>
					<hr />
					{modalState === 'sizes' && <SizeSelection />}
					{modalState === 'shipping' && <Shipping />}
					{modalState === 'processing' && (
						<Processing checkoutKey={checkoutKey} />
					)}
					{modalState === 'payment' && shippingInfo !== undefined && (
						<Payment />
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DevontaMintModal;
