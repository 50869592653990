import "./Drop2MintDetails.scss";

import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

import getDrop2PageContent from "../../drops/drop2PageContent";
import Drop2MintButton from "./Drop2MintButton";

// import { MintButton } from "./MintButton";

const Drop2MintDetails: React.FC<any> = (props) => {
  const { colorway } = props;

  const [selectedPerkTab, setSelectedPerkTab] = useState("Digital");

  const { description, nftArtUrl, sketchfabUrl, attributes, perks } =
    getDrop2PageContent(colorway);

  const getPerksContent = () => {
    const selectedPerks = perks[selectedPerkTab.toLowerCase()];

    return (
      <div className="drop2-perks-bullet-list">
        {selectedPerks.map((p, i) => (
          <div className="drop2-perks-bullet-item" key={i}>
            {p}
          </div>
        ))}
      </div>
    );
  };

  const getAttributesContent = () => {
    return (
      <div className="drop2-mint-details-attributes">
        <div className="drop2-mint-details-attributes-line">
          <div className="drop2-mint-details-attributes-header">Attributes</div>
        </div>
        {attributes.map((attr, i) => (
          <div className="drop2-mint-details-attributes-line" key={i}>
            <div className="drop2-mint-details-attributes-text">{attr.key}</div>
            <div className="drop2-mint-details-attributes-text">
              {attr.value}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="drop2-mint">
      <div className="drop2-mint-left">
        <div className="drop2-mint-nft">
          <video src={nftArtUrl} autoPlay muted loop />
          <MobileView>
            {
              <Drop2MintButton
                colorway={colorway}
                className="drop2-mint-details-button"
              />
            }
          </MobileView>
          <MobileView>
            {<div className="drop2-mint-description">{description}</div>}
          </MobileView>
          <div className="drop2-mint-nft-specs">
            <div className="drop2-perks-tab-container">
              <div
                className={`drop2-perks-tab ${
                  selectedPerkTab === "Digital"
                    ? "drop2-perks-tab-selected"
                    : "drop2-perks-tab-unselected"
                }`}
                onClick={() => setSelectedPerkTab("Digital")}
              >
                <p>Digital</p>
              </div>
              <div
                className={`drop2-perks-tab ${
                  selectedPerkTab === "Physical"
                    ? "drop2-perks-tab-selected"
                    : "drop2-perks-tab-unselected"
                }`}
                onClick={() => setSelectedPerkTab("Physical")}
              >
                <p>Physical</p>
              </div>
              <div
                className={`drop2-perks-tab ${
                  selectedPerkTab === "Experiential"
                    ? "drop2-perks-tab-selected"
                    : "drop2-perks-tab-unselected"
                }`}
                onClick={() => setSelectedPerkTab("Experiential")}
              >
                <p>Experiential</p>
              </div>
            </div>
            <div className="drops2-perks-content">{getPerksContent()}</div>
          </div>
        </div>
        <div className="drop2-mint-details">
          <BrowserView>
            {<div className="drop2-mint-description">{description}</div>}
          </BrowserView>
          <BrowserView>
            {/* <MintButton colorway={colorwayId} paperId={paperId} price={ price } />; */}
            {
              <Drop2MintButton
                colorway={colorway}
                className="drop2-mint-details-button"
                fullWidth
              />
            }
          </BrowserView>
          {getAttributesContent()}
        </div>
      </div>
      <div className="drop2-mint-model">
        <div className="sketchfab-embed-wrapper">
          <iframe
            title="Drop 2: Statecraft"
            frameBorder="0"
            allowFullScreen
            // webkitallowfullscreen="true"
            // mozallowfullscreen="true"
            scrolling="no"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking="true"
            execution-while-out-of-viewport="true"
            execution-while-not-rendered="true"
            web-share="true"
            src={sketchfabUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default Drop2MintDetails;
